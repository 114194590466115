import React from 'react';
import './DocumentOverlay.css';
import { isEnglish, hideDocumentOverlay } from './../utility/Lib';


const DocumentOverlay = (props) => {

    return (
        <dialog id="document_overlay" class="documentOverlay">
            <div class="documentOverlay_center">
                <div class="documentOverlay_inner">
                    <object id="do_document" class="documentOverlay_document" data={""} type="application/pdf" />
                </div>
                <button class="defaultButton white" onClick={() => {hideDocumentOverlay()}} style={{"margin":"30px", "width":"300px" }}>{isEnglish() ? "Close" : "Stäng"}</button>
            </div>
        </dialog>
    )
};

export default DocumentOverlay;
