import React, { Component } from 'react';
import './PrintSpinner.css';
import { isEnglish } from '../utility/Lib';
import { print } from '../utility/Zebra';
import img_printer from '../assets/reshot-icon-printer.svg';



/*

*/
class PrintSpinner extends Component {

	constructor(props) {
		super(props)

        // Global function declaration
        /*
            First parameter is print info, second is callback
            window.GlobalFunc_Print({
                visitorName: visitorName,
                visitorCompany: visitorCompany,
                checkInDate: checkInDate,
                employeeName: employeeName, 
                checkoutCode: checkoutCode
            },
            callback_OnComplete(success));
        */
        window.GlobalFunc_Print = this.Print;

		this.state = {
            IsLoading: true,
            Message: isEnglish() ? "Printing" : "Skriver ut",
            Fail: false
		};

        // Callback when printing is done. Can only handle one print request at a time else the callback will be overwriten
        this.printCB = null;
        this.isInUse = false;
        this.printInfo = {};
	}

    
    Print = async (printInfo, callback_OnComplete) => {

        if (this.isInUse) return; // Don't allow multiple prints at the same time

        this.isInUse = true;
        this.printCB = callback_OnComplete;
        this.printInfo = printInfo;
        document.getElementById('printOuterDiv').style.visibility = "visible";

        this.internal_Print();
    }

    internal_Print = async () => {

        this.setState({IsLoading: true})
        this.setState({Message: isEnglish() ? "Printing" : "Skriver ut"})
        this.setState({Fail: false})

        try {
            await print(this.printInfo);
            this.OnCompletion(true);

        }
        catch (error) {
            this.setState({Message: (isEnglish() ? "Error: " : "Fel: ") + error.message})
            this.setState({Fail: true})
        }
        finally {
            this.setState({IsLoading: false})
        }
    }

    ConfimError = () => {
        this.OnCompletion(false);
    }

    OnCompletion = (success) => {
        document.getElementById('printOuterDiv').style.visibility = "hidden";

        this.isInUse = false;
        if (this.printCB)
            this.printCB(success);
    }


    render(){
		return (
            <div className='printspinner' id='printOuterDiv'>
                <div className='printspinner_content'>
                    <img className='printspinner_img_printer' src={img_printer} alt="img_printer" ></img>
                    
                    { this.state.IsLoading && <div className='spinner'/> }
                    <div className='printspinner_message'>
                        { this.state.Message }
                    </div>
                    { this.state.Fail && <div className='printspinner_buttondiv'>
                        <button className='defaultButton printspinner_printbutton' onClick={this.internal_Print}>
                            {isEnglish() ? "Try again" : "Försök igen"}
                        </button>
                        <button className='defaultButton printspinner_continuebutton' onClick={this.ConfimError}>{isEnglish() ? "Continue" : "Fortsätt"}</button>
                    </div> }
                    
                </div>
            </div>
		);
	}

};

export default PrintSpinner;
