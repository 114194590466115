import "./SearchResult.css";

export const SearchResult = ({ result, onSelect }) => {

  //const [inputId, setInputId] = useState("SearchResult" + Math.random().toString()); // Generate random id, so we can get our specific element later

  return (
    <div
      //id="{inputId}"
      className="search-result"
      onClick={() => onSelect(result)}
    >
      {result}
    </div>
  );
};
