import React, { Component } from 'react';
import './VisitorsPage.css';
import StdContainer from '../components/StdContainer';
import api from '../api/api';
import { isEnglish, havePermission, searchUpdate } from '../utility/Lib';
import { FaUser, FaUserPlus } from 'react-icons/fa';


class VisitorsPage extends Component {


	constructor(props) {
		super(props)

		this.state = {
            IsLoading: true,
            receivedVisitors: false,
			Visitors: []
		};

		this.getVisitors();

        // If we scan in anyone while on this page make sure to update our list
        window.GlobalDelegate_OnCodeScanned = this.getVisitors;
	}



	getVisitors = () => {
        api("get", "/visitors/")
        .then((response) => {
            //alert(JSON.stringify(response.data))
            let visitorList = [];
            visitorList = response.data;

            // Sort by name
			visitorList.sort(function(a, b) {
				return a.name.toLowerCase() < b.name.toLowerCase() ? -1	: 1;
			  });

            // Sort by checked in
			visitorList.sort(function(a, b) {
				return a.isCheckedIn > b.isCheckedIn ? -1 : 1;
			  });

			this.setState({Visitors: visitorList, receivedVisitors: true})

        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
        .finally(() => { this.setState({IsLoading: false}) })
    }

    getCurrentVisitors = () => {
        return this.state.Visitors.filter(visitor => { return visitor && visitor.isCheckedIn; })
    }


    renderVisitorList = () => {
		if (this.state.Visitors.length === 0)
			return;

		return this.state.Visitors.map((visitor, index) => {
            let href = "/visitor?id=" + visitor._id;

            let iconStyle = visitor.isCheckedIn ? {"color": "#0099ff"} : {"color": "#000000"};
            let icon = visitor.rememberMe ? (<FaUserPlus style={iconStyle} />) : (<FaUser style={iconStyle} />);
			return (<div>
						<li>
                            <a href={href}>
                                <div class="visitor_name_icon">
                                    {icon}
                                    <div>{visitor.name}</div>
                                </div>
                                <div>{visitor.company}</div>
                            </a>
                        </li>
					</div>)
		})
	};
	

	render(){
		return (
			<StdContainer headerText={(isEnglish() ? "Visitor list" : "Besökslista")} isLoading={this.state.IsLoading}>
                 
                    { this.state.receivedVisitors && <div>
                        <h3>{isEnglish() ? `Checked-in visitors: ${this.getCurrentVisitors().length}/${this.state.Visitors.length}` : `Incheckade besökare: ${this.getCurrentVisitors().length}/${this.state.Visitors.length}`}</h3>

                        { this.state.Visitors.length > 4 &&
                            <input type="text" id="myInput" onKeyUp={searchUpdate} placeholder={isEnglish() ? "Search" :"Sök"} />}
                        
                        <div className='std_box1'>
                            <ul id="myUL" style={{"pointer-events": (havePermission("visitors_read") ? "auto" : "none")}}>
                                {this.renderVisitorList() }
                            </ul>
                        </div>
                    </div> }
				
			</StdContainer>
		);
	}
};

export default VisitorsPage;
