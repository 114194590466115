import { Component } from "react";

import "./SearchBox.css";
import { SearchBar } from "./SearchBar";
import { SearchResultsList } from "./SearchResultsList";
import api from '../../api/api';
import { isEnglish } from '../../utility/Lib';
//import ReactDOM from "react-dom";
        //const node = ReactDOM.findDOMNode(this);
        //node.


// expects url and getObjDisplayName
/*
  url = "/example"
  getObjDisplayName = (obj) => { return obj.name; }
  showAllOnEmpty = true/false
*/
class SearchBox extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
			results: [],
      searchString: this.props.hasOwnProperty("value") ? this.props.value : "",
      haveFocus: false,
      selectedObject: {},
      showHitsAtStrLen: this.props.showHitsAtStrLen ? this.props.showHitsAtStrLen : 0,
      //showExactHitsOnly: false,
      showIcon: this.props.hasOwnProperty("showIcon") ? this.props.showIcon : true,
      placeholder: this.props.hasOwnProperty("placeholder") ? this.props.placeholder : (isEnglish() ? "Search..." : "Sök...")
		}
    this.getObjDisplayName = this.props.hasOwnProperty("getObjDisplayName") ? this.props.getObjDisplayName : (obj) => { return obj.name; }
    this.getObjSearchName = this.props.hasOwnProperty("getObjSearchName") ? this.props.getObjSearchName : (obj) => { return obj.name; }
    this.onSelect = this.props.hasOwnProperty("onSelect") ? this.props.onSelect : (obj) => {}

    // Generate random id, so we can identify our own elements on click (or else we can't use multiple search boxes on a page)
    this.MyID = Math.random().toString();

    this.fetchData();
  }


  componentDidMount() {

    window.addEventListener('focusin', (e) => {
      if (!this.state.haveFocus) return;

      //console.log(this.MyID + " focus: " + e.target.id)
      if (e.target.id != this.MyID)
        this.setState({haveFocus: false})
    }, true);

    window.addEventListener('click', (e) => {
      if (!this.state.haveFocus) return;
      if (e.target.id == "background")
      {
        // You don't select anything here, your own text is left untouched(?)
        this.setState({haveFocus: false})
      }
    });

    window.addEventListener('keydown', (e) => { //keypress
      if (!this.state.haveFocus) return;
      if (e.key === 'Enter'/* || e.key === "Tab"*/) {

        // You don't select anything here, your own text is left untouched(?)
        if (this.state.results.length > 0)
          this.onObjectSelected(this.state.results[0]);

        this.setState({haveFocus: false})
        document.activeElement.blur();
      }
  });
  }

  fetchData = () => {
    api("get", this.props.url)
    .then((response) => {
      this.setState({data: response.data});
      this.state.data = response.data;
      this.updateResults();
    })
    //.catch((err) => { alert("err: " + JSON.stringify(err))})
  };

  updateResults = () => {
    let search = this.state.searchString;
    let allIfEmpty = this.props.showAllOnEmpty;
    
    const results = this.state.data.filter((obj) => {
      let searchName = this.getObjSearchName(obj);

      // Empty string
      if (!search)
      {
        if (allIfEmpty) return true; // Show all on empty
        else            return false;
      }

      if (!searchName) return false;
      
      if (this.props.showExactHitsOnly) return searchName.toLowerCase() == search.toLowerCase().trim();
      else                              return searchName.toLowerCase().includes(search.toLowerCase());
    });

    this.setState({results: results})
  }

  setSearchText = (text) => {
    this.onTextChanged(text);
  }


  onSearchBoxClicked = () => {
    this.setState({haveFocus: true})
    //this.onTextChanged("");
    this.updateResults();
  }

  onTextChanged = (text) => {
    this.setState({searchString: text})
    this.state.searchString = text;
    this.updateResults();
  }

  onObjectSelected = (object) => {
    if (!this.state.haveFocus) return;

    console.log(object._id)
    this.setState({selectedObject: object});
    this.setState({haveFocus: false})

    if (this.props.keepText)
      this.setState({searchString: this.getObjDisplayName(object)})

    this.onSelect(object);
  }



  render() {
    return (
      <div className="search-bar-container">

        {this.state.haveFocus && <div id="background" style={{width: "100vw", height: "100vh", background: "#00000000", top: "0px", left: "0px", position: "fixed"}} /> }
        <div id="foreground" style={{position: "relative"}} >
          <SearchBar id={this.MyID} showIcon={this.state.showIcon} text={this.state.searchString} placeholder={this.state.placeholder} onClick={this.onSearchBoxClicked} onTextChanged={this.onTextChanged} inputClass="" />
          {this.state.haveFocus && this.state.results.length >= 0 && this.state.searchString.length >= this.state.showHitsAtStrLen &&
          <SearchResultsList results={this.state.results} getObjDisplayName={this.getObjDisplayName} onSelect={this.onObjectSelected} />}
        </div>

        
      </div>
    );
  }
}

export default SearchBox;
