import "./SearchResultsList.css";
import { SearchResult } from "./SearchResult";

export const SearchResultsList = ({ results, getObjDisplayName, onSelect }) => {
  return (
    <div className="results-list">
      {results.map((result, id) => {
        return <SearchResult result={getObjDisplayName(result)} key={id} onSelect={() => onSelect(result)} />;
      })}
    </div>
  );
};
