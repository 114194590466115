import Branding from "./Branding";


// returns URL without the last /
export function getBackendURL () {
    let backend = process.env.REACT_APP_BACKEND;

    // Because we lock which IP that can access which visitor page URL we use custom backend url when accessing it localy
    // If we access the app on 'http://192.168.8.50:3000' then we try and access the api on 'http://192.168.8.50:5000'
    const href = window.location.href;
    const currentUrl = href.split('/').slice(0, 3).join('/');

    // Check if url contains port
    if (currentUrl.includes(":3")) {
        backend = currentUrl.replace(":3", ":5") // Api:s have port 5xxx, and client:s have port 3xxx
    }
        
    if (backend.endsWith("/"))
        backend = backend.slice(0, -1);

    return backend;
}


export function hideDocumentOverlay() {
    document.getElementById("document_overlay").close(); //removeAttribute("open");
}

export function showDocumentOverlay(documentUrl) {
    document.getElementById("document_overlay").showModal(); //setAttribute("open", "");
    document.getElementById("do_document").data = documentUrl;
}


export function isEnglish () {
    return localStorage.language === "eng";
}

export function setLanguageToEnglish () {
    localStorage.language = "eng";
}

export function setLanguageToSwedish () {
    localStorage.language = "swe";
}

export function toggleLanguage () {
    isEnglish() ? setLanguageToSwedish() : setLanguageToEnglish()
    window.location.reload()
}

// verify token here ?
/*api("get", "/auth/verify", { token: token }).then(function (response) {
			alert("verify: " + response)
		});*/
export function isLoggedIn () {
    return window.localStorage.getItem("Token");
}

export function loggOut () {
    window.localStorage.setItem("Token", "");
    window.localStorage.setItem('User', "")
    window.location.href = "/";
}

export function setUserObject (user) {
    window.localStorage.setItem('User', JSON.stringify(user))
}

export function getUserObject () {
    let string = window.localStorage.getItem("User");
    if (string && string !== 'undefined') {
        let user = JSON.parse(string);
        return user;
    }
    return null;
}

// This is only a copy of the real permissions on the server
export function setUserPermissions (permissions) {
    let user = getUserObject();
    user.permissions = permissions;
    window.localStorage.setItem('User', JSON.stringify(user));
}

// This is only a copy of the real permissions on the server
export function getUserPermissions () {
    let user = getUserObject();
    if (user) return user.permissions;
    return false;
}

export function havePermission (permission) {
    if (!getUserPermissions()[permission]) return false;
    return true;
}

// Will alert if not enough permissions and redirect to login page
// The real permission check is done at each api call on the server
export function permissionCheck (permission, queryString) {
    if (!getUserPermissions()[permission]) {
        if (isLoggedIn())
        {
            alert(isEnglish() ? "Insufficient permissions" : "Du har inte behörighet");
            window.history.back();
        }
        else
        {
            window.location.href = "/login?redir=" + window.location.pathname.substring(1) + (queryString ? queryString : "");
        }
        return false;
    }
    return true;
}


// This a copy of some of the real settings on the server
export function storeServerSettings (settings) {
    window.localStorage.setItem("serverSettings", JSON.stringify(settings));
    applyServerSettings();
}

export function applyServerSettings () {
    let settings = getServerSettings();
    if (!settings)
        return;

    // Style the page with the settings
    document.title = settings.companyName + (isEnglish() ? " Visitors" : " Besökare");
    Branding.setSiteColors(settings.color1, settings.color2);

    let imgs = document.getElementsByName("companyLogo");
    imgs.forEach((img) => {
        img.src = settings.companyLogo;
    })
    
    //document.getElementById("containerLogo").src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==' //"data:image/png;base64," + settings.companyLogo.data;
    //alert(document.getElementById("containerLogo").outerHTML)
}

// This a copy of some of the real settings on the server
export function getServerSettings () {
    let string = window.localStorage.getItem("serverSettings");
    if (string && string !== 'undefined') {
        let settings = JSON.parse(string);
        return settings;
    }
    return null;
}

// For <img>
export function getCompanyLogo () {
    let settings = getServerSettings();
    if (settings) return settings.companyLogo;
    return "";
}

export function getCompanyName () {
    let settings = getServerSettings();
    if (settings) return settings.companyName;
    return "";
}


export function usingPrinter () {
    let settings = getServerSettings();
    return settings && settings.usePrinter;
}


export function getUsername () {
    let user = getUserObject();
    if (user) return user.username;
    return "Error";
}

export function getCheckinDocumentSettings () {
    let settings = getServerSettings();
    if (settings) return settings.document;
    return {};
}






export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetters(string) {
    let newString = "";
    let array = string.split(" ");
    for (let i = 0; i < array.length; i++) {
        newString += capitalizeFirstLetter(array[i]);
        if (i !== array.length-1) newString += " ";
    }
    return newString;
}



// Takes a name and divides it up into name, middleName and surname
export function divideName (name) {
    let obj = {
        name: "",
        middleName: "",
        surname: "",
    }
    if (name && name.length > 0)
    {
        let array = name.split(" ");
        obj.name = array[0]
        if (array.length > 1)
        {
            if (array.length > 2)
            {
                for (let i = 1; i < array.length - 1; i++) {
                    obj.middleName += array[i] + (i === array.length - 2 ? "" : " ");
                }
            }
            obj.surname = array[array.length-1];
        }
        
    }
    
    return obj
}


export function searchUpdate () {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}