import React, { Component } from 'react';
import api from '../api/api';
import './CodeScanner.css';
import qs from "qs";
import StdContainer from './StdContainer';
import { isEnglish } from '../utility/Lib';
import img_check from '../assets/reshot-icon-check.svg';



class CodeScanner extends Component {

    constructor(props) {
      super(props)

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let codeParam = params.coc; // check out code from url

        // Global function declaration
        window.GlobalFunc_CheckoutByCode = this.checkoutByCode;

        this.isCheckingOut = false;
        this.isComplete = false; // When the checkout is done
        this.fadingOut = false; // Hide component earlier if we click
        this.timerHandler = null;


        // --- Hand scanner input ---
        // Use a scanner for a barcode / QR code looking like this #00453-23453-6234-2234
        // It must start with # then the checkout code
        // after typing all characters the hand scanner will press Enter

        this.input = "";
        this.isAcceptingInput = false;


        if (codeParam) this.checkoutByCode(codeParam);
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyDown, false);
    }
    
    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyDown);
    }

    checkoutByCode = (code, rememberMe = null) => {
        if (code) {
            this.input = code;
            this.checkOutVisitor(rememberMe);
        }
    }
    
    // Handle Hand Scanner inputs
    handleKeyDown = (event) => {
        if (event.key === "Shift" || event.key === "Alt" || event.key === "Ctrl")
            return;

        if (this.isCheckingOut) // Don't allow multiple checkouts at a time
            return;
        
        if (this.isAcceptingInput)
        {
            event.preventDefault();
            if (event.key === "Enter")
            {
                this.isAcceptingInput = false;
                this.checkOutVisitor();
            }
            else this.input += event.key;
        }
        else if (event.key === "#") 
        {
            this.isAcceptingInput = true;
            this.input = "";
        }
    }

    checkOutVisitor = (rememberMe = null) => {
        this.isCheckingOut = true;
        this.isComplete = false;

        let data = { checkoutCode: this.input }
        if (rememberMe != null)
            data.rememberMe = (rememberMe == true);
        
        api("post", "/visitors/check-Out", {}, data)
        .then((response) => {
            this.isCheckingOut = false;
            this.isComplete = true;
            document.getElementById('text1').innerHTML = isEnglish() ? "Welcome again!" : "Välkommen åter!";
            document.getElementById('text2').innerHTML = response.data;
            this.fadeIn();
        })
        .catch((err) => {
            this.isCheckingOut = false;
            this.isComplete = true;
            document.getElementById('text1').innerHTML = ""; //Math.floor(Math.random() * 100);
            if (err.response.status === 400) { document.getElementById('text2').innerHTML = isEnglish() ? "Already checked out" :  "Redan utcheckad"; }
            else                             { document.getElementById('text2').innerHTML = "(" + err.response.data + ")" }
            this.fadeIn();
        })
    }


    fadeIn = () => {
        this.fadingOut = false;

        document.getElementById('CS_outerDiv').style.visibility = "visible";
        document.getElementById('CS_outerDiv').classList.add("fadein");
        document.getElementById('CS_outerDiv2').classList.add("movein");

        if (this.timerHandler) window.clearTimeout(this.timerHandler);
        this.timerHandler = setTimeout(() => {
            this.fadeOut();
        }, 2500);
    }

    fadeOut = () => {
        if (this.fadingOut || this.isCheckingOut) return;
        
        this.fadingOut = true;

        document.getElementById('CS_outerDiv').classList.remove("fadein");
        document.getElementById('CS_outerDiv2').classList.remove("movein");

        if (this.timerHandler) window.clearTimeout(this.timerHandler);
        this.timerHandler = setTimeout(() => {

            this.fadingOut = false;
            document.getElementById('CS_outerDiv').style.visibility = "hidden";
            //window.location.reload(); // Reload to update visitor list on current page
            window.GlobalDelegate_OnCodeScanned(); // New method, any page can bind a function to this so they can update their content (Front page, Log)

        }, 200);
    }


    skip = () => {
        this.fadeOut();
    }


  render() {
    return (
        <div className='CodeScanner' id='CS_outerDiv' onClick={this.skip}>
            <div className='CodeScanner2' id='CS_outerDiv2'>
                <StdContainer headerText={isEnglish() ? "Checked out" : "Utcheckad"} >
                    
                    <div className='CodeScanner_content'>
                        <img className='CodeScanner_img' src={img_check} alt="img_check"></img>
                        <div className='CodeScanner_header' id='text1'>Message</div>
                        <div className='CodeScanner_message' id='text2'>Name</div>                  
                    </div>
                </StdContainer>
            </div>
        </div>
    );
  }
}

export default CodeScanner;