import React, { Component } from 'react';
import { loggOut } from './../utility/Lib';


// This page exists so users can write /logout if they have no other option
class LogoutPage extends Component {

    constructor(props) {
		super(props)

        loggOut();
	}

	render() {
		return (
            <div>Logging out</div>
		);
	}
};

export default LogoutPage;
