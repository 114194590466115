import React, { Component } from 'react';
import './PrinterPage.css';
import StdContainer from '../components/StdContainer';
import FormEntry from '../components/FormEntry';
import Moment from "moment";
import { isEnglish, permissionCheck, havePermission } from '../utility/Lib';

import img_warning from '../assets/reshot-icon-warning.svg';
import img_qr from '../assets/svgrepo-qr.svg';
import img_printer from '../assets/reshot-icon-printer.svg';



class PrinterPage extends Component {

    constructor(props) {
        super(props)

        // To clear run this code
        //localStorage.setItem("zplCode", "");
        //localStorage.setItem("zplCode_filename", "");

        this.state = {
            Code: localStorage.getItem("zplCode") ? localStorage.getItem("zplCode") : "",
            Filename: localStorage.getItem("zplCode_filename") ? localStorage.getItem("zplCode_filename") : "",
            CodeErrors: "",

            QR_UsePhone: localStorage.getItem("QR_UsePhone") ? localStorage.getItem("QR_UsePhone") === "true" : false,
            QR_URL: localStorage.getItem("QR_URL") ? localStorage.getItem("QR_URL") : "",
        };

        permissionCheck("printer_read");
    }

  componentDidMount() {
    this.checkForCodeErrors(this.state.Code);
    document.getElementById('qr_scanner').checked = !this.state.QR_UsePhone;
    document.getElementById('qr_phone').checked = this.state.QR_UsePhone;
    document.getElementById('qr_url').value = this.state.QR_URL;
    document.getElementById('qr_url').disabled = !this.state.QR_UsePhone;
  }


  loadFile = async (e) => {
    e.preventDefault();
    let fileName = e.target.files[0].name;

    const reader = new FileReader()
    reader.onload = async (e) => {

      const text = (e.target.result)
      this.setState({ Code: text, Filename: fileName });
      this.checkForCodeErrors(text);

    };
    reader.readAsText(e.target.files[0])
  }

    checkForCodeErrors = (code = "") => {
        let errors = "";
        let variables = [
            "var_visitor_name",
            "var_visitor_company",
            "var_date",
            "var_employee_name",
            "var_checkout_code",
        ]

        for (let i = 0; i < variables.length; i++) {
            if (!code.includes(variables[i])){
                if (errors.length > 0) errors += ", ";
                errors += variables[i];
            }
        }

        this.setState({ CodeErrors: errors });
    }

    updateDisabledElements = () => {
        document.getElementById('qr_url').disabled = !document.getElementById('qr_phone').checked;
    }


    print = () => {
        window.GlobalFunc_Print({
            visitorName: "Visitor Name",
            visitorCompany: "Visitor Company",
            checkInDate: Moment().format("HH:mm - MM/DD/YYYY"),
            employeeName: "Employee Name",
            checkoutCode: "b1e89b40-5741-4a16-a34b-4be879dd0493"
        });
    }


    save = () => {
        localStorage.setItem("zplCode", this.state.Code);
        localStorage.setItem("zplCode_filename", this.state.Filename);
        localStorage.setItem("QR_UsePhone", document.getElementById('qr_phone').checked);
        localStorage.setItem("QR_URL", document.getElementById('qr_url').value);

        alert(isEnglish() ? "Saved" : "Sparat")
    }
	

	render(){
		return (
      <div>
        <StdContainer headerText={isEnglish() ? "Printer" : "Skrivare"} >
          <div className='PrinterPage_headerhint'>{isEnglish() ? "(settings are stored localy)" : "(inställningar sparas lokalt)"}</div>

          <div className='PrinterPage_headerfile'>
            <img className='PrinterPage_icon_file' src={img_printer} alt="img_printer"></img>
            <h3 style={{"width" : "100%"}}>{isEnglish() ? "Print template: " : "Utskriftsmall: "}</h3>
            <div style={{"width" : "100%", "text-align": "right"}}>{this.state.Filename}</div>
          </div>

          
          { this.state.Code && this.state.CodeErrors && <div className='PrinterPage_errorbox'>
            <div className=' PrinterPage_errorbox_inner'>
              <div className=' PrinterPage_errorbox_inner_header'>
                <img className='PrinterPage_icon_warning' src={img_warning} alt="img_warning"></img>
                  {isEnglish() ? "Missing variables:" : "Saknade variabler: "}
              </div>
              {this.state.CodeErrors}
            </div>
          </div> }
          
          <div className='PrinterPage_codebox'>
            <div className=' PrinterPage_codebox_inner'>
              {this.state.Code}
            </div>
          </div>


          <div className='PrinterPage_headerfile'>
            <div style={{"width" : "100%", "textAlign": "right", "paddingRight" : "10px"}}>{isEnglish() ? "Submit new template: " : "Ange ny mall: "}</div>
            <input type="file" id="myfile" accept=".zpl,.prn" onChange={(e) => this.loadFile(e)} disabled={!havePermission("printer_write")} />
          </div>


          <div className='PrinterPage_headerfile'>
            <img className='PrinterPage_icon_qr' src={img_qr} alt="img_qr"></img>
            <h3 style={{"width" : "100%"}}>{isEnglish() ? "QR-Code: " : "QR-Kod: "}</h3>
          </div>


          <div className="PrinterPage_qr_radio_div">
            <div className="PrinterPage_qr_radio">
                <input className='PrinterPage_qr_radio_button' id="qr_scanner" type="radio" name="qr_method" onClick={this.updateDisabledElements} disabled={!havePermission("printer_write")}/>
                <label className='PrinterPage_qr_radio_label' for="qr_scanner">{isEnglish() ? "For connected scanner" : "För ansluten scanner"}</label>
            </div>
            
            <div className="PrinterPage_qr_radio">
                <input className='PrinterPage_qr_radio_button' id="qr_phone" type="radio" name="qr_method" onClick={this.updateDisabledElements} disabled={!havePermission("printer_write")} />
                <label className='PrinterPage_qr_radio_label' for="qr_phone">{isEnglish() ? "For mobile phone" : "För mobil-avläsning"}</label>
            </div>
            <div className="PrinterPage_qr_manual">
                <FormEntry id="qr_url" type="text" label={isEnglish() ? "Custom URL:" : "Specifik URL:"} placeholder={""}/>
            </div>
          </div>


          <div className='PrinterPage_buttondiv'>
            <button className='defaultButton checkincomplete_printbutton' onClick={this.print}>
              <img className='checkincomplete_icon' src={img_printer} alt="img_printer"></img>{isEnglish() ? "Print name tag test" : "Skriv ut testbricka"}
            </button>
            <button className='defaultButton' style={{"max-width": "200px", "width": "200px"}} onClick={this.save} hidden={!havePermission("printer_write")} >{isEnglish() ? "Save" : "Spara"}</button>
          </div>
                
        </StdContainer>
      </div>
		);
	}
};

export default PrinterPage;
