import React from 'react';
import './BackButton.css';
import img_back from '../assets/reshot-icon-arrow-left.svg';
import { isEnglish } from './../utility/Lib';


const BackButton = (props) => {
    return (
        <div className='btn_back_box' onClick={() => { props.href ? window.location.href = props.href : window.history.back()}}>
            <img className='btn_back_img' src={img_back} alt="img_back"/>
            { isEnglish() ? "Back" : "Tillbaka"}
        </div>
        
    )
};

export default BackButton;