import { FaSearch } from "react-icons/fa";

import "./SearchBar.css";

export const SearchBar = ({ id, showIcon, text, placeholder, onClick, onTextChanged }) => {

  const handleClick = (e) => {
    //e.target.setSelectionRange(0, e.target.value.length)
    onClick();
  }

  const handleChange = (value) => {
    onTextChanged(value);
  };


  return (
    <div className="input-wrapper">
      { showIcon && <FaSearch id="search-icon" /> }
      <input
        id={id}
        className="search-input"
        placeholder={placeholder}
        value={text}

        onClick={(e) => handleClick(e)}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={(e) => handleClick(e)}
      />
    </div>
  );
};
