import React, { Component } from 'react';
import './MainPage.css';
import StdContainer from '../components/StdContainer';
import api from '../api/api';
import { isEnglish, isLoggedIn, havePermission, searchUpdate } from '../utility/Lib';


class MainPage extends Component {


	constructor(props) {
		super(props)

		this.state = {};
	}


	

	render(){
		return (
			<StdContainer headerText={(isEnglish() ? "Visitors" : "Besökare")}>
				
                
                <div className="mainPage_checkin">

                    { havePermission("visitor_checkin") &&
                    <div class="round-button">
                        <div class="round-button-circle">
                            <a href="/checkin" class="round-button">{isEnglish() ? "Check In" :"Checka In"}</a>
                        </div>
                    </div>
                     }
                    
                    { havePermission("visitor_checkout") &&
                    <button class="checkoutButton" onClick={() => {window.location = "/checkout";}}>{isEnglish() ? "Check Out" :"Checka Ut"}</button>
                    }
                </div>
               
                				
			</StdContainer>
		);
	}
};

export default MainPage;
