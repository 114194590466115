import React, { Component } from 'react';
import './FormEntry.css';



class FormEntry extends Component {

	/* Arguments:
	
		id
		type
		label
		placeholder [optional]
	*/

    constructor(props) {
		super(props)

		this.state = {
            hover: false,
		};
	}

    setHover(active) {
        //alert(active ? "active" : "not")
        this.setState({hover: active})
    }

    GetStyle() {
        let myStyle = (this.state.hover && !this.props.disabled ? {background: "rgba(0, 0, 0, 0.05)"} : {background: "transparent"});
		let userStyle = this.props.style;
		return {...myStyle, ...userStyle};
    }

	render(){
		return (
			<div class="tooltip">
                
				<div className="formEntry" style={this.GetStyle()} onMouseEnter={() => {this.setHover(true)}} onMouseLeave={() => {this.setHover(false)}}
				onClick={() => {this.myInput.focus(); if(this.props.onClick) this.props.onClick();}}>
					
					{ this.props.tooltip &&
					<span class="tooltiptext">{this.props.tooltip}</span> }

					<label className={(this.props.type === "checkbox" ? 'formEntry_LabelCheckbox' : "formEntry_Label")} for={this.props.id} style={this.props.labelStyle}>
						<div>{this.props.label}</div>
						{this.props.children}
					</label>
					
					<div>
						<input type={this.props.type} name={this.props.name} id={this.props.id} className={(this.props.type === "checkbox" ? 'formEntry_InputCheckbox' : "formEntry_Input")} style={this.props.inputStyle} placeholder={this.props.placeholder} accept={this.props.accept} ref={(input) => { this.myInput = input; }} disabled={this.props.disabled} value={this.props.value} onChange={this.props.onChange}  ></input>
					</div>
					
				</div>
			</div>
		);
	}
};

export default FormEntry;
