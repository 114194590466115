import React, { Component } from 'react';
import './LogPage.css';
import StdContainer from '../components/StdContainer';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import { isEnglish, permissionCheck } from '../utility/Lib';

import img_arrow_left from '../assets/reshot-icon-arrow-left.svg';
import img_arrow_right from '../assets/reshot-icon-arrow-right.svg';
import Moment from 'moment';


class LogPage extends Component {

	constructor(props) {
		super(props)
		
		this.state = {
			IsLoading: true,
			LogEntries: [],
			SelectedDate: Moment(),
			ShowAllDays: false,
			ShowCompanies: false,
		};

        if (!permissionCheck("log")) return;
        
		this.getLogEntries();

        // If we scan in anyone while on this page make sure to update our list
        window.GlobalDelegate_OnCodeScanned = this.getLogEntries;
	}

	componentDidMount() {
		//document.getElementById("showAllDays").checked = true;
	}

	componentDidUpdate() {
		if (document.getElementById("selected_date"))
			document.getElementById("selected_date").value = Moment(this.state.SelectedDate).format("YYYY-MM-DD");
	}


	getLogEntries = () => {
        api("get", "/visitors/log")
        .then((response) => {
			//alert(JSON.stringify(response.data))
            // Sort by date
			response.data.sort(function(a, b) {
				return a.checkedInAt > b.checkedInAt ? -1 : 1;
			  });

			this.setState({LogEntries: response.data})
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
		.finally(() => { this.setState({IsLoading: false}) })
    }

	isTodaySelected = () => {
		return this.state.SelectedDate.dayOfYear() === Moment().dayOfYear();
	}

	getRightArrowClass = () => {
		let className = "logpage_img_arrow";
		if (this.isTodaySelected()) className += "_disabled";
		return className;
	}

	onLeftArrowClicked = () => {
		let newDate = Moment(this.state.SelectedDate);
		newDate.add(-1, "day");
		this.setState({SelectedDate: newDate})
	}

	onRightArrowClicked = () => {
		if (this.isTodaySelected())
			return;

		let newDate = Moment(this.state.SelectedDate);
		newDate.add(1, "day");
		this.setState({SelectedDate: newDate})
	}

	onDatePicked = () => {
		this.setState({SelectedDate: Moment(document.getElementById("selected_date").value)})
	}

	onShowAllDaysChanged = () => {
		this.setState({ShowAllDays: document.getElementById("showAllDays").checked})
	}

	onShowCompaniesChanged = () => {
		this.setState({ShowCompanies: document.getElementById("showCompanies").checked})
	}
	

	getSelectedDateText = () => {
		let date = Moment(this.state.SelectedDate);
		date = date.locale("sv");
		return date.format("llll");
	}
	

	getSelectedDayVisitors = () => {
		let visitors = [];
		this.state.LogEntries.map((entry, index) => {
			if (this.state.SelectedDate.dayOfYear() === Moment(entry.checkedInAt).dayOfYear()) {
				visitors.push(entry);
			}
            return false;
		});

		return visitors;
	}

	
	GetFilteredLogEntries = () => {
		let logEntries = this.state.ShowAllDays ? this.state.LogEntries : this.getSelectedDayVisitors();
		let filteredEntries = [];
		let input = document.getElementById("myInput");
		if (!input) return logEntries;

		let filter = input.value.toUpperCase();
		if (!filter) return logEntries;

		for (let i = 0; i < logEntries.length; i++) {
			const entry = logEntries[i];
			let matchFound = false;

			if (entry.visitor_name && entry.visitor_name.toUpperCase().indexOf(filter) > -1) 		matchFound = true;// Name did match
			if (entry.visitor_company && entry.visitor_company.toUpperCase().indexOf(filter) > -1)	matchFound = true; // Company did match
			if (matchFound) filteredEntries.push(entry);
		}

		return filteredEntries;
	}


    getCheckOutTime = (logEntry) => {
        if (!logEntry.checkedOutAt){
            return Moment(logEntry.checkedInAt).isSame(Moment(), "day") ? "" : "xx:xx";
        }

        return Moment(logEntry.checkedOutAt).format("HH:mm");
    }


	renderVisitorList = () => {
		if (this.state.LogEntries.length === 0)
			return;

		let curDate = Moment(this.state.LogEntries[this.state.LogEntries.length-1].checkedInAt);
		curDate.add(1, "day");

		let LogEntries = this.GetFilteredLogEntries();
		return LogEntries.map((entry, index) => {

			let dayTitle = null;
			if (this.state.ShowAllDays){
				let entryTime = Moment(entry.checkedInAt);
				if (entryTime.dayOfYear() !== curDate.dayOfYear())
				{
					curDate = entryTime;
					dayTitle = curDate.format("DD-MM-YYYY");
				}
			}

			let href = "/log/details?logid=" + entry._id;
			let checkInTime = Moment(entry.checkedInAt).format("HH:mm");
			let checkOutTime = this.getCheckOutTime(entry);//entry.checkedOutAt ? Moment(entry.checkedOutAt).format("HH:mm") : "";
			let rightText = this.state.ShowCompanies ? entry.visitor_company : checkInTime + " - " + checkOutTime;
			return (<div>
						{dayTitle && <div className='logpage_date_entry'>{dayTitle}</div>}
						<li><a href={href}><div>{entry.visitor_name}</div><div>{rightText}</div></a></li>
					</div>)
		})
	};

	update = () => {
		this.forceUpdate();
	}



	render(){
		return (
			<StdContainer headerText={isEnglish() ? "Visitor log" : "Besökslogg"} isLoading={this.state.IsLoading} >
				
				<div className='logpage_checkboxentry'>
					<FormEntry id="showAllDays" name="showAllDays" type="checkbox" label={isEnglish() ? "Show all days at once:" : "Visa alla dagar samtidigt:"} onChange={this.onShowAllDaysChanged} />
				</div>

				<div className='logpage_checkboxentry' style={{"margin-bottom": "10px"}}>
					<FormEntry id="showCompanies" name="showCompanies" type="checkbox" label={isEnglish() ? "Show companies:" : "Visa företag:"} onChange={this.onShowCompaniesChanged} />
				</div>

				{ !this.state.ShowAllDays && <div className='logpage_currentdate_div'>
					<img className='logpage_img_arrow' src={img_arrow_left} alt="img_arrow_left" onClick={this.onLeftArrowClicked}></img>
					<input className='logpage_date' type="date" id="selected_date" onChange={this.onDatePicked}></input>
					<img className={this.getRightArrowClass()} src={img_arrow_right} alt="img_arrow_right" onClick={this.onRightArrowClicked}></img>
				</div> }
				
				<input type="text" id="myInput" onKeyUp={this.update} placeholder={isEnglish() ? "Search" :"Sök"} />
				
				<div className='std_box1' style={{"max-height": "400px"}}>
					<ul id="myUL">
						{ this.renderVisitorList() }
					</ul>
				</div>

                <div className='logpage_checkboxentry' style={{"padding-top" : "6px"}}>
                    {isEnglish() ? "data is stored for 30 days" : "data sparas i 30 dagar"}  
                </div>
				
			</StdContainer>
		);
	}
};

export default LogPage;
