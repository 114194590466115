import axios from "axios";
import { getBackendURL } from "../utility/Lib"

export default async function (method = "get", path = "/", params = {}, data = {}, myHeaders = {}) 
{
    return new Promise((resolve, reject) => {

        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${window.localStorage.getItem('Token')}`,
            ...myHeaders
        }
            
        axios({
            method:         method,
            url:            getBackendURL() + "/api" + path,
            params:         params,
            data,           data,
            headers:        headers
        })
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        })
    });
}
