import React, { Component } from 'react';
import './VisitorDetails.css';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import qs from "qs";
import Moment from "moment";
import { havePermission, isEnglish, permissionCheck, usingPrinter } from '../utility/Lib';

import img_time from '../assets/reshot-icon-time.svg';
import img_house from '../assets/freesvg-house.svg';
import img_phone from '../assets/freesvg-phone.svg';
import img_employee from '../assets/employee-svgrepo-com.svg';
import img_printer from '../assets/reshot-icon-printer.svg';
import { FaUser, FaUserPlus } from 'react-icons/fa';
import { ImEnter, ImExit } from "react-icons/im";



class VisitorDetails extends Component {

	constructor(props) {
		super(props)

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		this.state = {
            IsLoading: true,
			VisitorId: params.id,
            Visitor: {},
		};

        if (!permissionCheck("visitors_read", window.location.search)) return;
	}

    componentDidMount() {
        this.getVisitor();
	}


    getVisitor = () => {
        api("get", "/visitors", { _id: this.state.VisitorId })
        .then((response) => {

            let checkinDate = response.data[0].checkinDate ? Moment(response.data[0].checkinDate).format("MM/DD/YYYY - HH:mm") : "-";
            let checkoutDate = response.data[0].checkoutDate ? Moment(response.data[0].checkoutDate).format("MM/DD/YYYY - HH:mm") : "-";
            
            //alert( JSON.stringify(response.data[0]))
			this.setState({Visitor: response.data[0]})
            document.getElementById("name").value = response.data[0].name;
            document.getElementById("company").value = response.data[0].company;
            document.getElementById("phone").value = response.data[0].phone;
            document.getElementById("date_checkin").value = checkinDate;
            document.getElementById("date_checkout").value = checkoutDate;
            document.getElementById("employee").value = response.data[0].employee_name;
            document.getElementById("rememberMe").checked = response.data[0].rememberMe;
        })
        .catch((err) => {
            //alert("error: " + err.response.data)
            window.location.href = "/visitors"; // Visitor already checked out probably
        })
        .finally(() => { this.setState({IsLoading: false}) })
    }

   
    checkOutVisitor = () => {
        window.GlobalDelegate_OnCodeScanned = () => {
            if (this.state.Visitor.rememberMe)  window.location.href = window.location.href;
            else                                window.location = "/visitors";
        }
        window.GlobalFunc_CheckoutByCode(this.state.Visitor.checkoutCode);
    }

    deleteVisitor = () => {
        if (!window.confirm(isEnglish() ? "The visitor will be deleted!" : "Besökaren kommer att tas bort!"))
            return;

        api("delete", "/visitors", { _id: this.state.Visitor._id })
        .then((response) => {
            window.location.href = "/visitors";
        })
        .catch((err) => {
            alert("error: " + JSON.stringify(err.response.data))
        })
    }

    print = () => {
        window.GlobalFunc_Print({
            visitorName: this.state.Visitor.name,
            visitorCompany: this.state.Visitor.company,
            checkInDate: Moment(this.state.Visitor.checkinDate).format("HH:mm - MM/DD/YYYY"),
            employeeName: this.state.Visitor.employee_name,
            checkoutCode: this.state.Visitor.checkoutCode
        });
    }


    getStatusText = () => {
        if (this.state.Visitor.isCheckedIn)
            return isEnglish() ? "Is checked in" : "Incheckad";
        else
            return isEnglish() ? "Not checked in" : "Ej Incheckad";
    }


    render(){
		return (
            <div>
                <BackButton />
                <StdContainer headerText={isEnglish() ? "Visitor" :"Besökare"} isLoading={this.state.IsLoading}>					
                    
                    <div class="checkin_input-container">
                        <FaUser className='checkin2_icon' style={this.state.Visitor.isCheckedIn ? {"color": "#0099ff"} : {"color": "#000000"}}/>
                        <input id="name" type="text" disabled/>
                    </div>

                    <div class="checkin_input-container">
                        <img className='checkin2_icon' src={img_house} alt="img_house"></img>
                        <input id="company" type="text" disabled/>
                    </div>

                    <div class="checkin_input-container">
                        <img className='checkin2_icon' style={{"height": "30px", "width": "30px", "margin-right": "-4px", "padding-left": "2px"}} src={img_phone} alt="img_phone"></img>
                        <input id="phone" type="tel" disabled />
                    </div>
        
                    <div class="checkin_input-container">
                        <img className='checkin2_icon' src={img_employee} alt="img_employee"></img>
                        <input id="employee" type="text" disabled />
                    </div>

                    <div class="checkin_input-container">
                        <FaUserPlus className='checkin2_icon' />
                        <div style={{width: "100%"}}>
                            <FormEntry id="rememberMe" name="rememberMe" type="checkbox" label={isEnglish() ? "Remember me:" : "Kom ihåg mig:"} style={{height: "45px"}} disabled/>
                        </div>
                    </div>



                    <div className='flexcolumn' style={{"margin-top": "20px", "font-weight": "bolder"}} >
                        { this.getStatusText() }
                    </div>


                    <div class="checkin_input-container" style={{"margin-top": "5px"}}>
                        <ImEnter className='checkin2_icon' />
                        <input id="date_checkin" type="text" disabled />
                    </div>


                    { !this.state.Visitor.isCheckedIn && 
                    <div class="checkin_input-container">
                        <ImExit className='checkin2_icon' />
                        <input id="date_checkout" type="text" disabled />
                    </div>
                    }

                    
                    { (havePermission("visitors_write") || havePermission("visitor_checkout")) && 
                    <div className='flexcolumn'>
                        { this.state.Visitor.isCheckedIn && 
                            <button class="checkoutButton" onClick={this.checkOutVisitor}>{isEnglish() ? "Check Out" :"Checka Ut"}</button>
                        }
                    </div>
                    }
                    

                    
                    <div className='VisitorDetails'>
                        { usingPrinter() && 
                        <button className='defaultButton checkincomplete_printbutton' onClick={this.print}>
                            <img className='checkincomplete_icon' src={img_printer} alt="img_printer"></img>{isEnglish() ? "Print name tag" : "Skriv ut namnbricka"}
                        </button>
                        }

                        { havePermission("visitors_write") && 
                        <button className='defaultButton red' style={{"max-width": "200px", "width": "200px"}} onClick={this.deleteVisitor}>
                            {isEnglish() ? "Delete visitor" : "Ta bort besökare"}
                        </button>
                        }
                    </div>

                    
                    
                    
                </StdContainer>
            </div>
		);
	}

};

export default VisitorDetails;
