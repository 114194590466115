

module.exports.updateSiteColors = function ()
{
    // Get the root element
    var r = document.querySelector(':root');
    r.style.setProperty('--Color1', window.localStorage.Color1 ? window.localStorage.Color1 : '#333');
    r.style.setProperty('--Color2', window.localStorage.Color2 ? window.localStorage.Color2 : 'rgb(60, 118, 175)');
}



module.exports.setSiteColors = function (color1, color2)
{
   // alert(color1);
    window.localStorage.Color1 = color1;
    window.localStorage.Color2 = color2;
    this.updateSiteColors();
}