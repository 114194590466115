import React, { Component } from 'react';
import './EmployeesPage.css';
import img_employee from '../assets/employee-svgrepo-com.svg';
import StdContainer from '../components/StdContainer';
import api from '../api/api';
import { isEnglish, permissionCheck, havePermission, searchUpdate } from '../utility/Lib';


class EmployeesPage extends Component {

	constructor(props) {
		super(props)
		this.state = {
			IsLoading: true,
			Employees: []
		};

        if (!permissionCheck("employees_read")) return;

		this.GetAllEmployees();
	}



	GetAllEmployees = () => {
        api("get", "/employees")
        .then((response) => {
            // Sort by name
			response.data.sort(function(a, b) {
				return a.name.toLowerCase() < b.name.toLowerCase() ? -1	: 1;
			  });

			this.setState({Employees: response.data})
        })
        .catch((err) => {
            //alert("error: " + err.response.data)
        })
		.finally(() => { this.setState({IsLoading: false}) })
    }

	
	// Go to modify employee page to create the new employee
	createEmployee = () => {
		window.location.href = "/employees/modify?new=true";
    }


	render(){
		return (
			<StdContainer headerText={isEnglish() ? "Employees" :"Anställda"} isLoading={this.state.IsLoading}>
				<input type="text" id="myInput"  onKeyUp={searchUpdate} placeholder={isEnglish() ? "Search" :"Sök"} />
				
				<div className='std_box1'>
					<ul id="myUL">
						{ this.state.Employees.map((value, index) => {
							return (<li><a href={"/employees/modify?id=" + value._id}><div><img className='checkin2_icon' style={{"margin-bottom" : "-3px"}} src={img_employee} alt="img_employee"/>{value.name}</div></a></li>)
						})
						}
					</ul>
				</div>

				{ havePermission("employees_write") && <div className='std_box2' style={{"padding-top": "10px", "float": "right"}}>
					<button className='defaultButton' style={{"max-width": "200px"}} onClick={this.createEmployee}>{isEnglish() ? "New Employee" : "Ny Anställd"}</button>
				</div> }
			</StdContainer>
		);
	}
};

export default EmployeesPage;
