import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import api from './api/api';

import './App.css';
import Header from './components/Header';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import EmployeesPage from './pages/EmployeesPage';
import EmployeeDetailsPage from './pages/EmployeeDetailsPage';
import UsersPage from './pages/UsersPage';
import UserDetailsPage from './pages/UserDetailsPage';
import CheckInPage from './pages/CheckInPage';
import CheckInCompletePage from './pages/CheckInCompletePage';
import CheckOutPage from './pages/CheckOutPage';
import VisitorDetails from './pages/VisitorDetails';
import PrinterPage from './pages/PrinterPage';
import LogPage from './pages/LogPage';
import LogPageDetails from './pages/LogDetailsPage';
import SettingsPage from './pages/SettingsPage';
import VisitorsPage from './pages/VisitorsPage';

import DocumentOverlay from './components/DocumentOverlay';
import CodeScanner from './components/CodeScanner';
import PrintSpinner from './components/PrintSpinner';
import { setUserPermissions, getUserPermissions, storeServerSettings, getServerSettings, isLoggedIn } from './utility/Lib'
import Branding from './utility/Branding';

var pjson = require('../package.json');



class App extends Component {

    constructor(props) {
		super(props)

        // Since we get the settings after first rendered, we need to reload the page immediately if anything have changed
        this.needReload = false;

        // Will set the colors to our previous values in localStorage
        Branding.updateSiteColors();

		    // This is called on each page load
        this.fetchPermissions()
        .finally(() => {
            this.fetchServerSettings()
            .finally(() => {

                if (this.needReload)
                {
                    window.location.href = window.location.href;
                }

            })
        })
        
	}

    // Gets permissions for the current logged in user, then cache them
    fetchPermissions = () => {
        return new Promise((resolve, reject) => {
            
            api("get", "/auth/permissions")
            .then((response) => {
                //alert("permissions: " + response.data)
                let old = getUserPermissions();
                setUserPermissions(response.data);
                
                if (JSON.stringify(old) != JSON.stringify(response.data))
                    this.needReload = true;
            })
            .catch((err) => {
                //alert("error: " + err.response.data)
            })
            .finally(() => { resolve(); })
        });
    }

    // But just the essential stuff (not all)
    fetchServerSettings = () => {
        return new Promise((resolve, reject) => {
            api("get", "/settings/essentials")
            .then((response) => {
                //alert("settings: " + JSON.stringify(response.data))
                let old = getServerSettings();
                storeServerSettings(response.data);
                if (JSON.stringify(old) != JSON.stringify(response.data))
                    this.needReload = true;
            })
            .catch((err) => {
                if (!err.response.data)
                  alert(err)
                else
                  alert("error: " + err.response.data)
            })
            .finally(() => { resolve(); })
        });
    }


  render() {
    return (
      <>
        <DocumentOverlay/>
        <Header />
        <CodeScanner />
        <PrintSpinner />
        <Router>
          <div className='defaultOuterDiv'>
            <Routes>
              <Route path="/"                   element={<MainPage />} />
              <Route path="*"                   element={<Navigate to="/" replace />} />
              <Route path="/login"              element={<LoginPage />} />
              <Route path="/logout"             element={<LogoutPage />} />
              <Route path="/employees"          element={<EmployeesPage />} />
              <Route path="/employees/modify"   element={<EmployeeDetailsPage />} />
              <Route path="/users"              element={<UsersPage />} />
              <Route path="/users/modify"       element={<UserDetailsPage />} />
              <Route path="/checkin"            element={<CheckInPage />} />
              <Route path="/checkin/complete"   element={<CheckInCompletePage />} />
              <Route path="/checkout"           element={<CheckOutPage />} />
              <Route path="/visitor"            element={<VisitorDetails />} />
              <Route path="/settings/printer"   element={<PrinterPage />} />
              <Route path="/log"                element={<LogPage />} />
              <Route path="/log/details"        element={<LogPageDetails />} />
              <Route path="/settings"           element={<SettingsPage />} />
              <Route path="/visitors"           element={<VisitorsPage />} />
            </Routes>
          </div>
          <div class="version_number">v{pjson.version}</div>
        </Router>
      </>
    );
  }
}

export default App;