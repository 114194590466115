import React, { Component } from 'react';
import './SettingsPage.css';
import StdContainer from '../components/StdContainer';
import api from '../api/api';
import { isEnglish, havePermission, permissionCheck, getBackendURL, showDocumentOverlay } from '../utility/Lib';
import FormEntry from '../components/FormEntry';
import Branding from '../utility/Branding'
import img_pdf from '../assets/PDF_icon.svg';


class SettingsPage extends Component {
	
	constructor(props) {
        super(props)

		this.state = {
			IsLoading: true
		}

        if (!permissionCheck("settings_read")) return;

		this.fetch();
    }

	

	
	updateSiteColors = () => {
		let color1 = document.getElementById("siteColor1").value;
		let color2 = document.getElementById("siteColor2").value;
		Branding.setSiteColors(color1, color2);
	}


	componentDidMount() {
		let col1 = document.getElementById("siteColor1");
		let col2 = document.getElementById("siteColor2");
		col1.addEventListener("change", this.updateSiteColors);
		col2.addEventListener("change", this.updateSiteColors);
		col1.value = window.localStorage.Color1;
		col2.value = window.localStorage.Color2;
	}




	fetch = () => {

		api("get", "/settings")
		.then((response) => {
			document.getElementById("usePrinter").checked = response.data.usePrinter;
            document.getElementById("clearVisitors").checked = response.data.clearVisitorsEachNight;
			document.getElementById("notifyByMail").checked = response.data.notifyByMail;
			document.getElementById("notifyBySMS").checked = response.data.notifyBySMS;
			document.getElementById("companyName").value = response.data.companyName;
			//document.getElementById("companyLogo").value = response.data.companyLogo;
			
			let docSettings = response.data.document;
			document.getElementById("useDocument").checked = docSettings.useDocument;
			document.getElementById("document_useFile").checked = docSettings.useFile;
			document.getElementById("document_swedishText").value = docSettings.swedishText;
			document.getElementById("document_englishText").value = docSettings.englishText;

		})
		.catch((err) => {
            alert("Error: " + JSON.stringify(err.response))
		})
		.finally(() => { this.setState({IsLoading: false}) })
	}


	submit = (event) => {
		event.preventDefault();	
		var formData = new FormData(event.target);

		api("post", "/settings", {}, formData, {'Content-Type': 'multipart/form-data'})
		.then((response) => {
			//alert(isEnglish() ? "Saved" : "Sparat")
			window.location.href = window.location.href;
		})
		.catch((err) => {
            alert("Error: " + JSON.stringify(err.response))
		})
	}

	showDocument = () => {
        showDocumentOverlay(getBackendURL() + "/files/checkin.pdf");
    }



	render(){
		return (
			<StdContainer headerText={isEnglish() ? "Settings" : "Inställningar"} isLoading={this.state.IsLoading} >
				
				<form id="form" onSubmit={this.submit}>
					
					<div class="SettingsSection">
						<h3>{isEnglish() ? "Server settings:" : "Serverinställningar:"}</h3>
						<hr/>
						<FormEntry id="usePrinter" name="usePrinter" type="checkbox" label={isEnglish() ? "Use label printer:" : "Använd etikettskrivare:"} placeholder={""} disabled={!havePermission("settings_write")}  tooltip={isEnglish() ? "Shows/hides everything that has to do with label printers:" : "Visar/döljer allt som har med etikettskrivare att göra"}/>
						<FormEntry id="clearVisitors" name="clearVisitors" type="checkbox" label={isEnglish() ? "Clear visitors each night (3:00am):" : "Rensa besökare varje natt (kl 3:00):"} placeholder={""} disabled={!havePermission("settings_write")}/>
					</div>

					<div class="SettingsSection">
						<h3>{isEnglish() ? "Check-in:" : "Incheckning:"}</h3>
						<hr/>
						<FormEntry id="notifyByMail" name="notifyByMail" type="checkbox" label={isEnglish() ? "Notify employee by E-Mail:" : "Meddela anställd via E-Mail:"} placeholder={""} disabled={!havePermission("settings_write")} tooltip={isEnglish() ? "Will send an E-Mail to the selected employee when a visitor checks in.\n(Each employee have the ability to opt out of receiving notifications)" : "När en besökare checkar in meddelas vald anställd via E-Mail.\n(Alla anställda kan sedan välja om de ska få meddelanden eller inte)"}/>
						<FormEntry id="notifyBySMS" name="notifyBySMS" type="checkbox" label={isEnglish() ? "Notify employee by SMS:" : "Meddela anställd via SMS:"} placeholder={""} disabled={!havePermission("settings_write")} tooltip={isEnglish() ? "Will send a SMS to the selected employee when a visitor checks in.\n(Each employee have the ability to opt out of receiving notifications)" : "När en besökare checkar in meddelas vald anställd via SMS.\n(Alla anställda kan sedan välja om de ska få meddelanden eller inte)"}/>
					</div>

					<div class="SettingsSection">
						<h3>{isEnglish() ? "Document:" : "Dokument:"}</h3>
						<hr/>
						<FormEntry id="useDocument" name="useDocument" type="checkbox" label={isEnglish() ? "Activate:" : "Aktivera:"} placeholder={""} disabled={!havePermission("settings_write")} tooltip={isEnglish() ? "Then all visitors need to read and accept a document when checking in:" : "Då måste alla besökare läsa och godkänna ett dokument vid incheckning"}/>
						<FormEntry id="document_swedishText" name="document_swedishText" type="textbox" label={isEnglish() ? "Swedish:" : "Svenska:"} placeholder={"Jag har läst och godkänner dokumentet"} disabled={!havePermission("settings_write")}/>
						<FormEntry id="document_englishText" name="document_englishText" type="textbox" label={isEnglish() ? "English:" : "Engelska:"} placeholder={"I have read and accepts the document"} disabled={!havePermission("settings_write")}/>
						<FormEntry id="document_useFile" name="document_useFile" type="checkbox" label={isEnglish() ? "Use digital document:" : "Använd digitalt dokument:"} placeholder={""} disabled={!havePermission("settings_write")} style={{"margin-top": "32px"}} tooltip={isEnglish() ? "Use if no physical document exists at the check-in location" : "Använd om inget fysiskt dokument finns vid incheckningsplatsen"}/>
						<FormEntry id="document_file" name="document_file" type="file" accept=".pdf" label={isEnglish() ? "Upload PDF:" : "Ladda upp PDF:"} placeholder={""} disabled={!havePermission("settings_write")}/>
						<div class="defaultButton white checkin_input-container" style={{"padding": "6px", "margin-top": "6px", "border": "1px solid black"}} onClick={() => this.showDocument()}>
						    <img className='checkin2_icon' style={{"height": "30px", "width": "30px", "margin-right": "-5px", "padding-left": "3px"}} src={img_pdf} alt="img_pdf"></img>
							<div style={{"font-size": "13px"}}>{isEnglish() ? "Click to read" : "Tryck för att läsa"}</div>
						</div>
					</div>
					

					<div class="SettingsSection">
						<h3>{isEnglish() ? "Branding:" : "Varumärke:"}</h3>
						<hr/>
						<FormEntry id="siteColor1" name="siteColor1" type="color" label={isEnglish() ? "Color 1:" : "Färg 1:"} placeholder={""} disabled={!havePermission("settings_write")} inputStyle={{"padding": "0px", "height": "40px", "width": "40px"}}/>
						<FormEntry id="siteColor2" name="siteColor2" type="color" label={isEnglish() ? "Color 2:" : "Färg 2:"} placeholder={""} disabled={!havePermission("settings_write")} inputStyle={{"padding": "0px", "height": "40px", "width": "40px"}}/>
						<FormEntry id="companyName" name="companyName" type="textbox" label={isEnglish() ? "Company name:" : "Företagsnamn:"} placeholder={""} disabled={!havePermission("settings_write")}/>
						<FormEntry id="companyLogo" name="companyLogo" type="file" accept=".svg, .png, .jpeg, .jpg" label={isEnglish() ? "Logo (64x64):" : "Logga (64x64):"} placeholder={""} disabled={!havePermission("settings_write")}/>
					</div>

					<div className='PrinterPage_buttondiv'>
						<button id="fromSubmit" type="submit" className='defaultButton' style={{"max-width": "200px", "width": "200px"}} hidden={!havePermission("settings_write")} >{isEnglish() ? "Save" : "Spara"}</button>
					</div>
				</form>
			</StdContainer>
		);
	}
};

export default SettingsPage;
