import React from 'react';
import './Header.css';
import img_se from '../assets/se.svg';
import img_gb from '../assets/gb.svg';
import img_people from '../assets/svgrepo-people.svg';
import { isEnglish, toggleLanguage, isLoggedIn, loggOut, getUsername, havePermission, usingPrinter } from './../utility/Lib';


const Header = () => {

    function haveAnyMenuPerissions () {
        return havePermission("visitors_read") || havePermission("log") || havePermission("settings_read") || havePermission("printer_read")
        || havePermission("employees_read") || havePermission("users_read");
    }
   
    return (
        
        <div class="navbar">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

            <a href="/"><img className='header_imgLogo' src={img_people} alt="img_people"></img>{isEnglish() ? "Check-in" : "Incheckning"}</a>
            { haveAnyMenuPerissions() &&
            <div class="dropdown">
                <button class="dropbtn">{isEnglish() ? "Menu " : "Meny "}
                    <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                    { havePermission("visitors_read") && <a href="/visitors">{isEnglish() ? "Visitor list" : "Besökslista"}</a> }
                    { havePermission("log") && <a href="/log">{isEnglish() ? "Visitor log" : "Besökslogg"}</a> }
                    { havePermission("settings_read") && <a href="/settings">{isEnglish() ? "Settings" : "Inställningar"}</a> }
                    { havePermission("printer_read") && usingPrinter() && <a href="/settings/printer">{isEnglish() ? "Printer" : "Skrivare"}</a> }
                    { havePermission("employees_read") && <a href="/employees">{isEnglish() ? "Employees" : "Anställda"}</a> }
                    { havePermission("users_read") && <a href="/users">{isEnglish() ? "Users" : "Användare"}</a> }
                </div>
            </div> }


            <img className='header_imgFlag' src={isEnglish() ? img_se : img_gb} alt="img_se" onClick={toggleLanguage}/>

            <div class="float_right">
                {!isLoggedIn() && <a href="/login">{isEnglish() ? "Login" : "Logga in"}</a>}
         
                {isLoggedIn() && havePermission("show_user") &&
                 <div class="dropdown">
                    <button class="dropbtn">{getUsername() + " "}
                        <i class="fa fa-caret-down"></i>
                    </button>

                    <div class="dropdown-content right" style={{cursor: 'pointer'}}>
                        <a onClick={loggOut}>{isEnglish() ? "Logout" : "Logga ut"}</a>
                    </div>
                </div> }
            </div> 
        </div>
    )

};

export default Header;
