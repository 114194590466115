import React, { Component } from 'react';
import './StdContainer.css';
import { getCompanyLogo } from '../utility/Lib'
import img_logo from '../assets/MustadLogo.svg'


class StdContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			spinnerOpacity: 0
		}
	}
	
	componentDidMount() {
		setTimeout(() => {
            this.setState({spinnerOpacity: 1});
        }, 1);
		
    }

	render(){
		return (
			<div className={'std_outer1'} >
				<div className='std_outer2'>
					
					{ this.props.headerText &&
					<div className='std_header' style={{"min-height":"70px"}}>
						<div className='std_headerInside'>
							<img name="companyLogo" className='std_header_logo' src={getCompanyLogo()} alt="img_logo"/>
							<div className='std_headerText' style={{"color": this.props.headerColor}}>{this.props.headerText}</div>
						</div>
					</div> }


					<div class="std_content">

						{ this.props.isLoading &&
						<div style={{"width":"436px", "height":"236px", "display":"flex", "justify-content":"center", "align-items":"center", "position": 'absolute'}}>
							<div className='spinner std_spinner' style={{"opacity": this.state.spinnerOpacity}}/>
						</div>
						}
						
						<div style={{"width":"100%", "height":"100%", display: this.props.isLoading ? "none": "block"}}>
							{this.props.children}
						</div>
						
					</div>

				</div>
			</div>
		);
	}
};

export default StdContainer;
