import { isEnglish } from '../utility/Lib';
import ZebraBrowserPrintWrapper from '../zebra-browser-print-wrapper/lib/index'; // Copied from node_modules to local folder
//const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper'); // Module could not export it's content while in node_modules for some reason



// Sends zpl code to the default printer and prints it
export async function printZPL (zpl, checkForErrors_dontPrint) {
    let browserPrint = {}
    let defaultPrinter = {}
    let printerStatus = {}

    // Create the wrapper class
    try { browserPrint = new ZebraBrowserPrintWrapper(); } catch (error) { throw error; }

    // Select default printer
    try { defaultPrinter = await browserPrint.getDefaultPrinter(); } catch (error) {
        throw new Error((isEnglish() ? "Could not get the default printer from Zebra Browser Print. Make sure the app is running and have correct settings." :
                             "Kunde inte hämta standardskrivaren från Zebra Browser Print. Se till att appen kör och har rätt inställningar.")
                             + "\n" + error.message); }

    try {
        // Set the printer
        browserPrint.setPrinter(defaultPrinter);

        // Check printer status
        printerStatus = await browserPrint.checkPrinterStatus();
    } catch (error) { throw error; }


    try {
        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {
            // ZPL script to print a simple barcode
            /*let serial = 0123456789;
            const zpl = `^XA
                            ^BY2,2,100
                            ^FO20,20^BC^FD${serial}^FS
                            ^XZ`;*/

            if (!checkForErrors_dontPrint) {
                browserPrint.print(zpl);
            }
        }
        else { 
            if (printerStatus.errors.includes("Unknown Error")) {
                throw new Error(isEnglish() ? "Could not communicate with the printer. Make sure it's plugged in to the computer and powered on." :
                                               "Kunde inte kommunicera med skrivaren. Se till att den är ansluten till datorn och påslagen."); 
            }
            else throw new Error(printerStatus.errors);
        }
    } catch (error) { throw error; }
};


// Tries to print a visitor's name tag
/*
data = {
    visitorName: visitorName,
    visitorCompany: visitorCompany,
    checkInDate: checkInDate,
    employeeName: employeeName, 
    checkoutCode: checkoutCode
}
*/
export async function print(printInfo, checkForErrors_dontPrint) {
    try {
        
        let zplCode = localStorage.getItem("zplCode")
        if (!zplCode){
            throw new Error(isEnglish() ? "No print template set!\nPlease go to Menu->Printer and select a file" :
                                          "Utskriftmall ej vald!\nGå in i Meny->Skrivare och välj en fil");
        }

        // Make sure the code is in correct format here. Based on our settings
        // checkoutCode: "#" + "b1e89b40-5741-4a16-a34b-4be879dd0493", // for hand held scanner
        // checkoutCode: window.location.host + "?coc=b1e89b40-5741-4a16-a34b-4be879dd0493asdasdf"

        let QR_URL = "";
        let QR_usePhone = localStorage.getItem("QR_UsePhone") ? localStorage.getItem("QR_UsePhone") === "true" : false;
    
        if (QR_usePhone) {
            let customURL = localStorage.getItem("QR_URL") ? localStorage.getItem("QR_URL") : null;
            if (customURL) QR_URL = customURL + "?coc=" + printInfo.checkoutCode;
            else QR_URL = window.location.host + "?coc=" + printInfo.checkoutCode; 
        }
        else {
            QR_URL = "#" + printInfo.checkoutCode;
        }

        zplCode = zplCode.replace("var_visitor_name",    printInfo.visitorName);
        zplCode = zplCode.replace("var_visitor_company", printInfo.visitorCompany);
        zplCode = zplCode.replace("var_date",            printInfo.checkInDate);
        zplCode = zplCode.replace("var_employee_name",   printInfo.employeeName);
        zplCode = zplCode.replace("var_checkout_code",   QR_URL);
        

        await printZPL(zplCode, checkForErrors_dontPrint);

    } catch (error) {
        throw error;
    }
};