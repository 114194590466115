import React, { Component } from 'react';
import './CheckOutPage.css';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import SearchBox from '../components/search/SearchBox';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import qs from "qs";
import Moment from "moment";

import { isEnglish, permissionCheck, usingPrinter } from '../utility/Lib';

import img_person from '../assets/freesvg-person.svg';
import img_house from '../assets/freesvg-house.svg';
import { FaUserPlus } from 'react-icons/fa';



class CheckOutPage extends Component {

	constructor(props) {
		super(props)

		this.state = {
            SelectedVisitor: {},
            LockedInChoice: false,
        };

		if (!permissionCheck("visitor_checkout")) return;
	}
    


    onVisitorSelected = (visitor) => {
        this.setState({SelectedVisitor: visitor, LockedInChoice: true});
        document.getElementById("visitor_name").innerHTML = visitor.name;
        document.getElementById("visitor_company").innerHTML = visitor.company;
        document.getElementById("visitor_rememberMe").checked = visitor.rememberMe;

        document.getElementById("visitor_info").style.display = "block";
        document.getElementById("visitor_searchbox").style.display = "none";
    }


    getCheckoutText = () => {
        if (this.state.LockedInChoice) return isEnglish() ? "Is this you?" : "Är detta du?";
        if (usingPrinter()) return isEnglish() ? "Scan your nametag or enter your name:" : "Skanna din namnbricka eller skriv in ditt namn:";
        else                return isEnglish() ? "Enter your name:" : "Skriv in ditt namn:";
    }

    visitorListFormat = (obj) => {
        if (obj.company) return obj.name + " - " + obj.company;
        else             return obj.name;
    }

    checkOutVisitor = () => {
        window.GlobalDelegate_OnCodeScanned = function () { window.location = "/"; }
        window.GlobalFunc_CheckoutByCode(this.state.SelectedVisitor.checkoutCode, document.getElementById("visitor_rememberMe").checked);
    }
  

    render(){
		return (
            <div>
                <BackButton href="/" />
                <StdContainer headerText={isEnglish() ? "Check Out" :"Checka Ut"} isLoading={this.state.IsLoading}>

                    
                    

                    <h3 style={{"textAlign":"center", "padding-top": "10px"}}>{this.getCheckoutText()}</h3>
                    <div id="visitor_searchbox" style={{"display": "block", width: "100%", "padding-top": "3px"}}>
                        <SearchBox url="/visitors/current" showExactHitsOnly={true} onSelect={this.onVisitorSelected} getObjDisplayName={this.visitorListFormat} />
                    </div>
                    


                    <div id="visitor_info" style={{"display": "none"}}>

                        <div style={{"box-shadow": "2px 2px 4px 3px rgba(0, 0, 0, 0.2)", "border-radius":"15px", "padding-left": "15px", "padding-right": "15px"}}>

                            <div class="input-container" style={{width:"100%", height:"40px", "border-radius": "5px"}}>
                                <img className='icon' src={img_person} alt="img_person"></img>
                                <div id="visitor_name"></div>
                            </div>

                            <div class="input-container" style={{width:"100%", height:"40px", "border-radius": "5px"}}>
                                <img className='icon' src={img_house} alt="img_house"></img>
                                <div id="visitor_company"></div>
                            </div>

                            <div class="input-container">
                                <FaUserPlus className='icon' />
                                <div style={{width: "100%"}}>
                                    <FormEntry id="visitor_rememberMe" name="rememberMe" type="checkbox" label={isEnglish() ? "Remember me:" : "Kom ihåg mig:"} style={{height: "45px"}}/>
                                </div>
                            </div>

                        </div>

                        <div class="centerColumn" style={{"padding-top": "30px"}}>
                            <button class="checkoutButton" onClick={this.checkOutVisitor}>{isEnglish() ? "Yes, check out" :"Ja, checka ut"}</button>
                            <button class="checkoutButton checkoutBlue" onClick={() => {window.location = "/checkout";}}>{isEnglish() ? "No, go back" :"Nej, gå tillbaka"}</button>
                            
                        </div>
                    </div>
                    

                        
                </StdContainer>
            </div>
		);
	}

};

export default CheckOutPage;
