import React, { Component } from 'react';
import './UserDetailsPage.css';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import qs from "qs";
import { isEnglish, permissionCheck, havePermission, getUsername } from '../utility/Lib';



class UserDetailsPage extends Component {

	constructor(props) {
		super(props)

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		this.state = {
            IsLoading: true,
            isNew: params.new === "true",
			username: params.username.toLowerCase(),
		};

        this.toggleState = false;

		if (!permissionCheck("users_read", window.location.search)) return; // but you need write to modify anything
	}

    componentDidMount() {
        if (!this.state.isNew) // If we are modifying an existing user, get user info
        {
            api("get", "/users", { username: this.state.username })
            .then((response) => {
                //alert(JSON.stringify(response.data))
                var userdata = response.data[0];
                if (userdata){
                    document.querySelector("#perm_visitors_r").checked = userdata.permissions.visitors_read;
                    document.querySelector("#perm_visitors_w").checked = userdata.permissions.visitors_write;
                    document.querySelector("#perm_users_r").checked = userdata.permissions.users_read;
                    document.querySelector("#perm_users_w").checked = userdata.permissions.users_write;
                    document.querySelector("#perm_employees_r").checked = userdata.permissions.employees_read;
                    document.querySelector("#perm_employees_w").checked = userdata.permissions.employees_write;
                    document.querySelector("#perm_printer_r").checked = userdata.permissions.printer_read;
                    document.querySelector("#perm_printer_w").checked = userdata.permissions.printer_write;
                    document.querySelector("#perm_settings_r").checked = userdata.permissions.settings_read;
                    document.querySelector("#perm_settings_w").checked = userdata.permissions.settings_write;
                    document.querySelector("#perm_log").checked = userdata.permissions.log;
                    
                    document.querySelector("#perm_visitor_checkin").checked = userdata.permissions.visitor_checkin;
                    document.querySelector("#perm_visitor_checkout").checked = userdata.permissions.visitor_checkout;

                    document.querySelector("#perm_show_user").checked = userdata.permissions.show_user;
                    
                    this.disableUpdate();
                }
            })
            .catch((err) => { // User not found
                alert(err.message)
                window.location.href = "/users";
            })
            .finally(() => { this.setState({IsLoading: false}) })
        }
        else
        {
            this.setState({IsLoading: false})
        }
    }

    getFormData = () => {
        let data = {
            username: this.state.username,
            permissions: {
                visitors_read: document.querySelector("#perm_visitors_r").checked,
                visitors_write: document.querySelector("#perm_visitors_w").checked && !document.querySelector("#perm_visitors_w").disabled,
                users_read: document.querySelector("#perm_users_r").checked,
                users_write: document.querySelector("#perm_users_w").checked && !document.querySelector("#perm_users_w").disabled,
                employees_read: document.querySelector("#perm_employees_r").checked,
                employees_write: document.querySelector("#perm_employees_w").checked && !document.querySelector("#perm_employees_w").disabled,
                printer_read: document.querySelector("#perm_printer_r").checked,
                printer_write: document.querySelector("#perm_printer_w").checked && !document.querySelector("#perm_printer_w").disabled,
                settings_read: document.querySelector("#perm_settings_r").checked,
                settings_write: document.querySelector("#perm_settings_w").checked && !document.querySelector("#perm_settings_w").disabled,
                log: document.querySelector("#perm_log").checked,
                
                visitor_checkin: document.querySelector("#perm_visitor_checkin").checked,
                visitor_checkout: document.querySelector("#perm_visitor_checkout").checked,

                show_user: document.querySelector("#perm_show_user").checked,
            }
        }

        let pw = document.getElementById("pw").value;
        if (pw !== "") {
            data.password = pw;
        }

        return data;
    }

	addUser = () => {
		api("post", "/users/add", {}, { user: this.getFormData() })
        .then((response) => {
            //alert(response.data)
            window.location.href = "/users";
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

	modifyUser = () => {
        let userData = this.getFormData();

		api("put", "/users/modify", {}, { user: userData })
        .then((response) => {
            // If we modified our own user's permissions we need to update our client copy
            if (this.state.username === getUsername())
            {
                let user = JSON.parse(window.localStorage.getItem('User'));
                user.permissions = userData.permissions;
                window.localStorage.setItem('User', JSON.stringify(user))
            }
            //alert(response.data)
            window.location.href = "/users";
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

	removeUser = () => {

        if (!window.confirm(isEnglish() ? "Remove user?" : "Ta bort användaren?")) {
            return;
          }

        api("post", "/users/remove", {}, { userToRemove: this.state.username })
        .then((response) => {
            //alert(response.data)
            window.location.href = "/users";
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }
	

	getPwHint = () => {
        if (this.state.isNew) return isEnglish() ? "Enter a password" : "Skriv in ett lösenord" 
        else                  return isEnglish() ? "Leave blank to not change" : "Lämna blank för att inte ändra"}

    getHeaderText = () => {
        if (this.state.isNew) return isEnglish() ? "New user" : "Ny användare"
        else                  return isEnglish() ? "User" : "Användare"}


    // Disable child permissions when parent get unchecked
    disableUpdate = () => {

        if (!havePermission("users_write"))
        {
            var container = document.querySelector('div');
            var checkboxesAll = container.querySelectorAll('input[type="checkbox"]');
            for (let i = 0; i < checkboxesAll.length; i++) {
            checkboxesAll[i].disabled = true;
            }

            document.getElementById("pw").disabled = true; // also disable password box
        }
        else
        {
            document.querySelector("#perm_settings_w").disabled = !document.querySelector("#perm_settings_r").checked;
            document.querySelector("#perm_printer_w").disabled = !document.querySelector("#perm_printer_r").checked;
            document.querySelector("#perm_employees_w").disabled = !document.querySelector("#perm_employees_r").checked;
            document.querySelector("#perm_users_w").disabled = !document.querySelector("#perm_users_r").checked;
            document.querySelector("#perm_visitors_w").disabled = !document.querySelector("#perm_visitors_r").checked;
        }

        this.updateToggleButton();
    }


    areAllPermsChecked = () => {
        var container = document.querySelector('#div_permissions');
        var checkboxesAll = container.querySelectorAll('input[type="checkbox"]');
        var checkBoxesSelected = container.querySelectorAll('input[type="checkbox"]:checked');
        return checkboxesAll.length === checkBoxesSelected.length;
    }

    toggleAllPerms = () => {
        if (!havePermission("users_write")) return;

        this.toggleState = !this.toggleState;
        var container = document.querySelector('#div_permissions');
        var checkboxesAll = container.querySelectorAll('input[type="checkbox"]');
        for (let i = 0; i < checkboxesAll.length; i++) {
            const element = checkboxesAll[i];
            element.checked = this.toggleState;
        }

        this.disableUpdate();
    }

    updateToggleButton = () => {
        this.toggleState = this.areAllPermsChecked();
        document.querySelector("#toggle_all").checked = this.toggleState;
    }


	render(){
		return (
            <div>
                <BackButton />
                <StdContainer headerText={this.getHeaderText()} isLoading={this.state.IsLoading} >
                
                    <FormEntry id="username" type="text" label={(isEnglish() ? "Username:" : "Användarnamn:")} value={this.state.username} disabled={true} /> 
                    <FormEntry id="pw"       type="password" label={isEnglish() ? "Password:" : "Lösenord:"} placeholder={this.getPwHint()} />

              
                    <div className='modifyusers_permissions_div'>
                    <div className='perm_head_big'>{isEnglish() ? "Permissions" : "Behörighter"}</div>
                        <FormEntry id="toggle_all" type="checkbox" onClick={this.toggleAllPerms} />
                        <hr/>

                    <div id="div_permissions">

                        <div className='perm_head'>{isEnglish() ? "Cosmetic" : "Kosmetiskt"}</div>
                        <div class="tooltip" style={{"paddingLeft" : "20px"}}>
                            <span class="tooltiptext" style={{"white-space" : "pre-line"}}>{isEnglish() ? 'Including the "Logout" button.\nIf the button is hidden you have to type "/logout" in the URL' : 'Inklusive "Logga ut" knappen.\nOm knappen är dold krävs det att man skriver "/logout" i adressraden'}</span>
                            <FormEntry id="perm_show_user"            type="checkbox" label={isEnglish() ? "Show logged in user:" : "Visa inloggad användare:"} onClick={this.disableUpdate}/>
                        </div>
                        <hr/>
                        
                        <div className='perm_head'>{isEnglish() ? "Check-in" : "Incheckning"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_visitor_checkin"     type="checkbox" label={isEnglish() ? "Check in:" : "Checka in:"} onClick={this.disableUpdate} />
                            <FormEntry id="perm_visitor_checkout"     type="checkbox" label={isEnglish() ? "Check out:" : "Checka ut:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>
                        
                        <div className='perm_head'>{isEnglish() ? "Visitor list" : "Besökslista"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_visitors_r"            type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate}/>
                            <FormEntry id="perm_visitors_w"            type="checkbox" label={isEnglish() ? "Write:" : "Skriva:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>

                        <div className='perm_head'>{isEnglish() ? "Visitor log:" : "Besökslogg"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_log" type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>

                        <div className='perm_head'>{isEnglish() ? "Settings" : "Inställningar"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_settings_r"         type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate}/>
                            <FormEntry id="perm_settings_w"         type="checkbox" label={isEnglish() ? "Write:" : "Skriva:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>

                        <div className='perm_head'>{isEnglish() ? "Printer" : "Skrivare"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_printer_r"         type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate}/>
                            <FormEntry id="perm_printer_w"         type="checkbox" label={isEnglish() ? "Write:" : "Skriva:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>

                        <div className='perm_head'>{isEnglish() ? "Employees" : "Anställda"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_employees_r"        type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate}/>
                            <FormEntry id="perm_employees_w"        type="checkbox" label={isEnglish() ? "Write:" : "Skriva:"} onClick={this.disableUpdate} />
                        </div>
                        <hr/>

                        <div className='perm_head'>{isEnglish() ? "Users" : "Användare"}</div>
                        <div style={{"paddingLeft" : "20px"}}>
                            <FormEntry id="perm_users_r"            type="checkbox" label={isEnglish() ? "Read:" : "Läsa:"} onClick={this.disableUpdate}/>
                            <FormEntry id="perm_users_w"            type="checkbox" label={isEnglish() ? "Write:" : "Skriva:"} onClick={this.disableUpdate} />
                        </div>

                    </div>
                    </div>


                    { havePermission("users_write") && <div className='modifyusers_btncontainer'>

                        <button className='defaultButton modifyusers_btn' style={{"background-color": "red", visibility: this.state.isNew ? "hidden" : "visible"}} onClick={this.removeUser}>{isEnglish() ? "Remove user" : "Ta bort"}</button>
                        {this.state.isNew && <button className='defaultButton modifyusers_btn' onClick={this.addUser}>{isEnglish() ? "Create user" : "Skapa användare"}</button>}
                        {!this.state.isNew && <button className='defaultButton modifyusers_btn' style={{"background-color": "dodgerblue"}} onClick={this.modifyUser}>{isEnglish() ? "Save changes" : "Spara ändringar"}</button>}
                        
                    </div> }

                </StdContainer>
            </div>
		);
	}
};

export default UserDetailsPage;
