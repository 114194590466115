import React, { Component } from 'react';
import './CheckInCompletePage.css';
import StdContainer from '../components/StdContainer';
import qs from "qs";
import { isEnglish, isLoggedIn, capitalizeFirstLetters, usingPrinter } from '../utility/Lib';
import img_printer from '../assets/reshot-icon-printer.svg';




class CheckInCompletePage extends Component {

	constructor(props) {
		super(props)

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		this.state = {
            EmployeeId: params.employeeId,
            PrintInfo: params.printInfo
		};

		if (!isLoggedIn()) {
			window.location.href = "/login"; }

        // Go back to main page after a minute
        setTimeout(function() {
            window.location.href = "/";
        }, 60 * 1000);
	}

    hrefGroupMemberCheckin = () => {
        let query = qs.stringify({ employeeId: this.state.EmployeeId, visitorCompany: this.state.PrintInfo.visitorCompany}) 
        return "/checkin?" + query;
    }

    print = () => {
        window.GlobalFunc_Print(this.state.PrintInfo);
    }


    render(){
		return (
            <div>
                <StdContainer headerColor="#4b97c4" headerText={isEnglish() ? "Welcome" :"Välkommen"} >

                <div className="checkincomplete_text">
                    <div className='checkincomplete_bold'>{capitalizeFirstLetters(this.state.PrintInfo.visitorName)}</div>

                    <div>{isEnglish() ? "Remember to check out before leaving!" : "Kom ihåg att checka ut när du lämnar!"}</div>
                
                    
                    <div className="checkincomplete_roundbutton">
                        <div class="round-button3"><div class="round-button3-circle"><a href={this.hrefGroupMemberCheckin()} class="round-button3">{isEnglish() ? "+ Check In Group Member" :"+ Checka In Gruppmedlem"}</a></div></div>
                    </div>
                 

                    <div className='checkincomplete_buttonsdiv'>
                        { usingPrinter() && 
                        <button className='defaultButton checkincomplete_printbutton' onClick={this.print}>
                            <img className='checkincomplete_icon' src={img_printer} alt="img_printer"></img>{isEnglish() ? "Print name tag" : "Skriv ut namnbricka"}
                        </button> }
                        <button className='defaultButton' style={{"max-width": "200px", "width": "200px"}} onClick={() => {window.location.href = "/MainPage"}}>{isEnglish() ? "Done" : "Klar"}</button>
                    </div>
                </div>

                </StdContainer>
            </div>
		);
	}

};

export default CheckInCompletePage;
