import React, { Component } from 'react';
import './LogDetailsPage.css';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import api from '../api/api';
import qs from "qs";
import Moment from "moment";
import { isEnglish, permissionCheck } from '../utility/Lib';

import img_person from '../assets/freesvg-person.svg';
import img_house from '../assets/freesvg-house.svg';
import img_phone from '../assets/freesvg-phone.svg';
import img_time from '../assets/reshot-icon-time.svg';
import img_calender from '../assets/reshot-icon-calendar-boxed.svg';
import img_employee from '../assets/employee-svgrepo-com.svg';




class LogDetailsPage extends Component {

	constructor(props) {
		super(props)

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		this.state = {
            IsLoading: true,
            LogID: params.logid,
			LogEntry: {}
		};

        let query = "?" + qs.stringify({ logid: params.logid })
        if (!permissionCheck("log", query)) return;
       
	}

    componentDidMount() {
        this.getLogEntry();
    }


    getLogEntry = () => {
        api("get", "/visitors/log", { _id: this.state.LogID })
        .then((response) => {
			this.setState({LogEntry: response.data[0]})

            document.getElementById("visitor").value = response.data[0].visitor_name;
            document.getElementById("company").value = response.data[0].visitor_company;
            document.getElementById("phone").value = response.data[0].visitor_phone;
            document.getElementById("employee").value = response.data[0].employee_name;
        })
        .catch((err) => {
            console.log("error: " + JSON.stringify(err.response));
        })
        .finally(() => { this.setState({IsLoading: false}) })
    }

    getDates = () => {
        let checkInDate = Moment(this.state.LogEntry.checkedInAt);
        let dateText = checkInDate.format("MM/DD/YYYY");

        if (this.state.LogEntry.checkedOutAt)
        {
            let checkOutDate = Moment(this.state.LogEntry.checkedOutAt);
            if (checkInDate.dayOfYear() != checkOutDate.dayOfYear())
            {
                dateText += " - " + checkOutDate.format("MM/DD/YYYY");
            }
        }

        return dateText;
    }    

    getCheckOutTime = () => {
        if (!this.state.LogEntry.checkedOutAt){
            return Moment(this.state.LogEntry.checkedInAt).isSame(Moment(), "day") ? "" : isEnglish() ? "Didn't check out" : "Checkade inte ut";
        }

        return Moment(this.state.LogEntry.checkedOutAt).format("HH:mm");
    }


    render(){
		return (
            <div>
                <BackButton />
                <StdContainer headerText={isEnglish() ? "Log details" : "Loggdetaljer"} isLoading={this.state.IsLoading} >
 
                    <div className='logDetailsPage'>

                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' src={img_person} alt="img_person"></img>
                            <div>{this.state.LogEntry.visitor_name}</div>
                        </div>

                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' src={img_house} alt="img_house"></img>
                            <div>{this.state.LogEntry.visitor_company}</div>
                        </div>

                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' style={{"height": "30px", "width": "30px", "margin-right": "-5px", "padding-left": "3px"}} src={img_phone} alt="img_phone"></img>
                            <div>{this.state.LogEntry.visitor_phone}</div>
                        </div>

                        
                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' style={{"height": "24px", "width": "24px", "margin-right": "-4px", "padding-left": "6px"}} src={img_calender} alt="img_calender"></img>
                            <div>{this.getDates()}</div>
                        </div>

                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' style={{"height": "28px", "width": "28px", "margin-right": "-5px", "padding-left": "3px"}} src={img_time} alt="img_time"></img>
                            <div>{Moment(this.state.LogEntry.checkedInAt).format("HH:mm") + " - " + this.getCheckOutTime()}</div>
                        </div>
                        

                        <div class="logDetailsPage_div">
                            <img className='logDetailsPage_icon' src={img_employee} alt="img_employee"></img>
                            <div>{this.state.LogEntry.employee_name}</div>
                        </div>
					
                    </div>
 
                </StdContainer>
            </div>
		);
	}

};

export default LogDetailsPage;
