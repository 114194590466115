import React, { Component } from 'react';
import './LoginPage.css';
import { isEnglish } from '../utility/Lib';
import api from '../api/api';
import qs from "qs";


class LoginPage extends Component {

    constructor(props) {
		super(props)
		this.state = {
			loginError: false
		};
	}

    tryLogin = () => {

        this.setState({loginError: false})
        let username = document.getElementById("user").value
        let password = document.getElementById("pw").value

        api("post", "/auth/sign-in", {}, {
            username: username.toLowerCase(),
            password: password,
        })
        .then((response) => {
            if (response.data.message) alert(response.data.message)
            window.localStorage.setItem('User', JSON.stringify(response.data.user))
            window.localStorage.setItem('Token', response.data.token)

            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            window.location.href = "/" + params.redir;
            // TODO: Här kan vi redirecta till en default sida om användaren inte har lov att checka in/ut besökare.
            // Vi kan då också dölja Incheckningsknappen top left (?)
        })
        .catch((err) => {
            if (err.code === "ERR_NETWORK")
                alert("Fel: Kunde inte kommunicera med servern: " + "api: " + process.env.REACT_APP_BACKEND + " Error: " + err)
            
            this.setState({loginError: true})
            window.localStorage.setItem('User', "")
            window.localStorage.setItem('Token', "")
        })
    }

    onUserKeyDown = (event) => {
		if(event.key === "Enter") {
            document.getElementById("pw").focus();
        }
	}

    onPwKeyDown = (event) => {
		if(event.key === "Enter") {
            this.tryLogin();
        }
	}
	

	render() {
		return (
            <div className='loginPage'>
                <div className='loginPageContainer'>
				    
                    <label for="user">{isEnglish() ? "Usename" : "Användarnamn"}</label>
                    <input type="text" id="user" placeholder="" onKeyDown={(event) => this.onUserKeyDown(event)}></input>
                    <label for="pw">{isEnglish() ? "Password" : "Lösenord"}</label>
                    <input type="password" id="pw" placeholder="" onKeyDown={(event) => this.onPwKeyDown(event)}></input>

                    { this.state.loginError &&
                        <div className='loginPageError'>{isEnglish() ? "Wrong username or password!" : "Fel användarnamn eller lösenord!"}</div> }
                    <button className='defaultButton' onClick={this.tryLogin}>{isEnglish() ? "Login" : "Logga in"}</button>
                    
			    </div>
            </div>
		);
	}
};

export default LoginPage;
