import React, { Component } from 'react';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import qs from "qs";
import { isEnglish, permissionCheck, capitalizeFirstLetters, havePermission } from '../utility/Lib';




class ModifyEmployeesPage extends Component {

	constructor(props) {
		super(props)

        
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		this.state = {
            isNew: params.new === "true",
			id: params.id,
            fullName: "None",
            writeAccess: true,
		};

        if (!permissionCheck("employees_read", window.location.search)) return;
	}

    componentDidMount() {

		// Get the employee here
        if (!this.state.isNew && this.state.id) // If we are modifying an existing employee
        {
            api("get", "/employees", { _id: this.state.id })
            .then((response) => {
                //alert(JSON.stringify(response.data))
                var data = response.data[0];
                if (data){
                    document.getElementById("name").value = data.name;
                    document.getElementById("email").value = data.mail;
                    document.getElementById("phone").value = data.phone;
                    document.getElementById("notifyByMail").checked = data.notifyByMail;
                    document.getElementById("notifyBySMS").checked = data.notifyBySMS;
                    

                    this.setState({fullName: document.getElementById("name").value}) 

                    if (!havePermission("employees_write")) {
                        this.setState({writeAccess: false});
                    }
                }
            })
            .catch((err) => { // failed
                alert("error: " + err.response.data)
                window.location.href = "/employees";
            })
        }
        else
        {
            document.getElementById("notifyByMail").checked = true;
            document.getElementById("notifyBySMS").checked = true;
        }
	}


    getFormData = () => {
        
        let name = document.getElementById("name").value;
        let data = {
            name:           capitalizeFirstLetters(name),
            mail:           document.getElementById("email").value,
            phone:          document.getElementById("phone").value,
            notifyByMail:   document.getElementById("notifyByMail").checked,
            notifyBySMS:    document.getElementById("notifyBySMS").checked,
        }

        if (this.state.id) {
            data._id = this.state.id;
        }

        return data;
    }


    addEmployee = () => {
        api("post", "/employees/add", {}, { employee: this.getFormData() })
        .then((response) => {
            window.location.href = "/employees";
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

    modifyEmployee = () => {
        api("put", "/employees/modify", {}, { employee: this.getFormData() })
        .then((response) => {
            window.location.href = "/employees";
            //window.location.reload()
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

    removeEmployee = () => {

        if (!window.confirm(isEnglish() ? "Remove employee?" : "Ta bort anställd?")) {
            return;
        }

        api("post", "/employees/remove", {}, { employee: this.getFormData() })
        .then((response) => {
            window.location.href = "/employees";
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

    getHeaderText = () => {
        if (this.state.isNew) {
            return (isEnglish() ? "New Employee" : "Ny Anställd")}
        else {
            return this.state.fullName }
    }

   


    render(){
		return (
            <div>
                <BackButton />
                <StdContainer headerText={this.getHeaderText()}>
                        
                        <div class="SettingsSection">
                            <FormEntry id="name" type="text" disabled={!this.state.writeAccess} label={isEnglish() ? "Name:" : "Namn:"} />
                            <FormEntry id="email" type="email" disabled={!this.state.writeAccess} label={isEnglish() ? "Email:" : "E-Post:"} />
                            <FormEntry id="phone" type="tel" disabled={!this.state.writeAccess} label={isEnglish() ? "Phone Number:" : "Telefonnummer:"} />
                        </div>

                        <div class="SettingsSection">
                            <h3>{isEnglish() ? "Allow notifications about visitors:" : "Tillåt meddelanden om besökare:"}</h3>
                            <hr/>
                            <FormEntry id="notifyByMail" name="notifyByMail" type="checkbox" label={isEnglish() ? "By E-Mail:" : "Via E-Mail:"} placeholder={""} disabled={!this.state.writeAccess}/>
                            <FormEntry id="notifyBySMS" name="notifyBySMS" type="checkbox" label={isEnglish() ? "By SMS:" : "Via SMS:"} placeholder={""} disabled={!this.state.writeAccess}/>
                        </div>
                                                
                        { havePermission("employees_write") && <div className='modifyusers_btncontainer'>

                            <button className='defaultButton modifyusers_btn' style={{"background-color": "red", visibility: this.state.isNew ? "hidden" : "visible"}} onClick={this.removeEmployee}>{isEnglish() ? "Remove" : "Ta bort"}</button>
                            {this.state.isNew && <button className='defaultButton modifyusers_btn' onClick={this.addEmployee}>{isEnglish() ? "Create employee" : "Skapa anställd"}</button>}
                            {!this.state.isNew && <button className='defaultButton modifyusers_btn' style={{"background-color": "dodgerblue"}} onClick={this.modifyEmployee}>{isEnglish() ? "Save changes" : "Spara ändringar"}</button>}
                            
                        </div> }

                    </StdContainer>
            </div>
		);
	}

};

export default ModifyEmployeesPage;
