import React, { Component } from 'react';
import './UsersPage.css';
import StdContainer from '../components/StdContainer';
import api from '../api/api';
import { isEnglish, permissionCheck, searchUpdate, havePermission } from '../utility/Lib';


class UsersPage extends Component {

	constructor(props) {
		super(props)
		this.state = {
			IsLoading: true,
			users: []
		};

        if (!permissionCheck("users_read")) return;

        this.getAllUsers();
	}


	getAllUsers = () => {
		api("get", "/users")
		.then((response) => {
			// Sort by name
			response.data.sort(function(a, b) {
				return a.username.toLowerCase() < b.username.toLowerCase() ? -1	: 1;
			  });

			this.setState({users: response.data})
		})
		.catch((err) => {
            alert(err.response.data)
		})
		.finally(() => { this.setState({IsLoading: false}) })

	}


	// Go to modify user page to create the new user
	createUser = () => {
		let usertext = document.getElementById("newUser");
		if (usertext.value === "")
			return alert(isEnglish() ? "You need to enter a Username" : "Du måste ange ett Användarnamn")
		
		window.location.href = "/users/modify?username=" + usertext.value + "&new=true";
    }

	// Try create user if pressing enter while typing name
	onUserKeyDown = (event) => {
		if(event.key === "Enter") {
            this.createUser();
        }
	}


	
	render(){
		return (
			<StdContainer headerText={isEnglish() ? "Users" :"Användare"} isLoading={this.state.IsLoading}>
	

				<input type="text" id="myInput"  onKeyUp={searchUpdate} placeholder={isEnglish() ? "Search" : "Sök"} />
				
				<div className='std_box1'>
					<ul id="myUL">
						{ this.state.users.map((value, index) => {
                            let permCount = 0;
                            Object.keys(value.permissions).forEach(function(perm) { if (value.permissions[perm]) permCount++; });

							let permissions = permCount + "/" + Object.keys(value.permissions).length + (isEnglish() ? " Permissions" : " Behörigheter");
							return (<li><a href={"/users/modify?username=" + value.username}><div>{value.username}</div><div>{permissions}</div></a></li>)
						})
						}
					</ul>
				</div>

				{ havePermission("users_write") && <div>
                <h3 for="user">{isEnglish() ? "Create new User" : "Skapa ny Användare"}</h3>
				<div className='std_box2'>
					
					<input type="text" id="newUser" placeholder={isEnglish() ? "Usename" : "Användarnamn"} onKeyDown={this.onUserKeyDown}></input>
					<button className='defaultButton' style={{"max-width": "200px"}} onClick={this.createUser}>{isEnglish() ? "Create" : "Skapa"}</button>
					
				</div>
                </div> }
			</StdContainer>
		);
	}
};

export default UsersPage;
