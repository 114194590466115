import React, { Component } from 'react';
import './CheckInPage.css';
import StdContainer from '../components/StdContainer';
import BackButton from '../components/BackButton';
import SearchBox from '../components/search/SearchBox';
import FormEntry from '../components/FormEntry';
import api from '../api/api';
import qs from "qs";
import Moment from "moment";

import img_person from '../assets/freesvg-person.svg';
import img_house from '../assets/freesvg-house.svg';
import img_phone from '../assets/freesvg-phone.svg';
import img_employee from '../assets/employee-svgrepo-com.svg';
import img_pdf from '../assets/PDF_icon.svg';
import { isEnglish, permissionCheck, usingPrinter, getBackendURL, showDocumentOverlay, getCheckinDocumentSettings } from '../utility/Lib';
import { FaUserPlus } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';





class CheckIn2Page extends Component {

	constructor(props) {
		super(props)

		this.state = {
            IsLoading: false,
            Employee: {},
            Company: "",
            foundVisitor: null,
            prevEmployeeId: null // Used to determine if we need to message the employee. If all group members have the same employee don't send multiple messages.
		};

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (params.employeeId) {
            this.state.Employee._id = params.employeeId;
            this.state.prevEmployeeId = params.employeeId;
        }
        if (params.visitorCompany) {
            this.state.Company = params.visitorCompany;
        }
    
		if (!permissionCheck("visitor_checkin")) return;

        if (this.state.Employee._id)
            this.GetEmployee(this.state.Employee._id);
        
        this.SearchEmployeeRef = React.createRef();
        this.IsCheckingIn = false; // To avoid double checkin

        this.checkinDoc = getCheckinDocumentSettings();
	}
    

    componentDidMount() {
		if (this.state.Company) {
            document.getElementById("company").value = this.state.Company;
        }
	}


    onNameChanged = (name) => {
        let params = {
            name: name.toLowerCase(),
            company: this.state.Company ? this.state.Company: null,
            rememberMe: true,
        }

        api("get", "/visitors", params)
        .then((response) => {
            if (!Array.isArray(response.data) || response.data.length == 0)
                return;

            this.setState({foundVisitor: response.data[0]});

            document.getElementById("company").value = response.data[0].company;
            document.getElementById("phone").value = response.data[0].phone;
            if (this.checkinDoc.useDocument)
                document.getElementById("iHaveRead").checked = true;
            document.getElementById("rememberMe").checked = true;

            if (!this.state.Employee._id) {
                this.setState({Employee: {_id: response.data[0].employee_id, name: response.data[0].employee_name}});
                this.SearchEmployeeRef.current.setSearchText(response.data[0].employee_name);
            }
    
        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
    }

    onEmployeeSelected = (employee) => {
        this.setState({Employee: employee});
    }

    GetEmployee = (id) => {
        this.setState({IsLoading: true})

        api("get", "/employees/names", { _id: id })
        .then((response) => {
            this.setState({Employee: response.data[0]});
            this.SearchEmployeeRef.current.setSearchText(response.data[0].name);
        })
        .catch((err) => {
            //alert("error: " + err.response.data)
        })
        .finally(() => { this.setState({IsLoading: false}) })
    }


    getFormData = () => {  
        let name = document.getElementById("name").value;
        let data = {
            name:       name.toLowerCase(),
            company:    document.getElementById("company").value,
            phone:      document.getElementById("phone").value,
            rememberMe: document.getElementById("rememberMe").checked,
        }
        return data;
    }


    checkInVisitor = async () => {     
        let formdata = this.getFormData();
        if (!formdata.name)
        {
            alert(isEnglish() ? "Name is required" :"Namn saknas")
            return;
        }

        if (this.checkinDoc.useDocument)
        {
            let haveRead = document.getElementById("iHaveRead").checked;
            if (!haveRead)
            {
                alert(isEnglish() ? "You have to read and accept the document" :"Du måste läsa och godkänna dokumentet")
                return;
            }
        }
        
        if (!this.state.Employee._id)
        {
            alert(isEnglish() ? "Responsible personnel is required" :"Ansvarig personal saknas")
            return;
        }
        
        // Avoid multiple checkins
        if (this.IsCheckingIn) return;
        this.IsCheckingIn = true;



        /*  --- Check in to the server ---  */

        api("post", "/visitors/check-in", {},
			{
				visitor:            formdata,
                employeeId:         this.state.Employee._id,
				visitorID:          this.state.foundVisitor ? this.state.foundVisitor._id : null, // Send id of found visitor so it can be updated
                messageEmployee:    this.state.prevEmployeeId != this.state.Employee._id
            }
        )
        .then(async (response) => {

            /*  --- Print name tag, if we use printer ---  */

            let printInfo = {
                visitorName: formdata.name,
                visitorCompany: formdata.company,
                checkInDate: Moment().format("HH:mm - MM/DD/YYYY"),
                employeeName: this.state.Employee.name,
                checkoutCode: response.data.checkoutCode
            }
            
            let goNextPage = (success) => {
                // Check in complete
                let query = qs.stringify({ employeeId: this.state.Employee._id, printInfo: printInfo}) 
                window.location.href = "/checkin/complete?" + query;
            }

            if (usingPrinter()) window.GlobalFunc_Print(printInfo, goNextPage);
            else                goNextPage();

        })
        .catch((err) => {
            alert("error: " + err.response.data)
        })
        .finally(() => { this.IsCheckingIn = false; })
    }


    showDocument = () => {
        showDocumentOverlay(getBackendURL() + "/files/checkin.pdf");
    }
  

    render(){
		return (
            <div>
                <BackButton href="/" />
                <StdContainer headerColor="#4b97c4" headerText={isEnglish() ? "Check In" :"Checka In"} isLoading={this.state.IsLoading}>

                    

                    <h3>{isEnglish() ? "Your info:" : "Dina uppgifter:"}</h3>

                    <div class="checkin_input-container">
                        <img className='checkin2_icon' src={img_person} alt="img_person"></img>
                        <input id="name" type="text" onBlur={(e) => this.onNameChanged(e.target.value)} placeholder={(isEnglish() ? "Name" : "Namn") + " *"} />
                    </div>

                    <div class="checkin_input-container">
                        <img className='checkin2_icon' src={img_house} alt="img_house"></img>
                        <input id="company" type="text" placeholder={isEnglish() ? "Company" : "Företag"} />
                    </div>

                    <div class="checkin_input-container" style={{"padding-bottom": "10px", }}>
                        <img className='checkin2_icon' style={{"height": "30px", "width": "30px", "margin-right": "-5px", "padding-left": "3px"}} src={img_phone} alt="img_phone"></img>
                        <input id="phone" type="tel" placeholder={isEnglish() ? "Phone Number" : "Telefonnummer"} />
                    </div>

                    
                    { this.checkinDoc.useDocument && this.checkinDoc.useFile && <div class="checkin_input-container">
                        <button class="defaultButton white checkin_input-container" style={{"margin-left": "15px", "padding": "6px", "border": "1px solid black"}} onClick={() => this.showDocument()}>
                            <img className='checkin2_icon' style={{"height": "30px", "width": "30px", "margin-right": "-5px", "padding-left": "3px"}} src={img_pdf} alt="img_pdf"></img>
                            <div style={{"font-size": "13px"}}>{isEnglish() ? "Click to read" : "Tryck för att läsa"}</div>
                        </button>
                    </div> }

                    { this.checkinDoc.useDocument && <div class="checkin_input-container">
                        <IoDocumentTextOutline className='checkin2_icon' />
                        <div style={{width: "100%"}}> 
                            <FormEntry id="iHaveRead" name="iHaveRead" type="checkbox" label={(isEnglish() ? this.checkinDoc.englishText : this.checkinDoc.swedishText) + ": *"} style={{height: "45px"}} />
                        </div>        
                    </div> }
                    

                    <div class="checkin_input-container">
                        <FaUserPlus className='checkin2_icon' />
                        <div style={{width: "100%"}}>
                            <FormEntry id="rememberMe" name="rememberMe" type="checkbox" label={isEnglish() ? "Remember me:" : "Kom ihåg mig:"} style={{height: "45px"}}/>
                        </div>
                    </div>
                    

                    <h3>{isEnglish() ? "Responsible personnel:" : "Ansvarig personal:"}</h3>

                    <div class="checkin_input-container">
                        <img className='checkin2_icon' src={img_employee} alt="img_employee"></img>
                        <div style={{width: "100%"}}>
                            <SearchBox ref={this.SearchEmployeeRef} url="/employees/names" showIcon={false} keepText={true} showAllOnEmpty={true} onSelect={this.onEmployeeSelected} />
                        </div>
                    </div>


                    <div className='std_box2' style={{"padding-top": "25px", "float": "right"}}>
                        <button className='defaultButton' style={{"max-width": "200px", "width": "200px"}} onClick={this.checkInVisitor}>{isEnglish() ? "Check in" : "Checka in"}</button>
                    </div>
                    

                        
                </StdContainer>
            </div>
		);
	}

};

export default CheckIn2Page;
